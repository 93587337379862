import React, { Component } from "react";
import { colors } from "../colors";
import { fontSizes } from "../fontSizes";
import { fontWeights } from "../fontWeights";
import {
  fetchProjectHeadquarters,
  getPropertiesByProject,
  updateFloor,
  updateProperty,
} from "../api";
import { Spring, animated } from "@react-spring/web";
import { withRouter } from "../withRouter";
import { Row, Col, Spinner } from "react-bootstrap";

import {
  ListUl,
  MapFill,
  BarChartFill,
  Filter,
  PenFill,
  CheckLg,
} from "react-bootstrap-icons";
import MapView from "./MapView";
import PropertyModal from "./PropertyModal";
import TableView from "./TableView";
import ChartView from "./ChartView";
import PropertyFilter from "./PropertyFilter";

class Properties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      properties: [],
      view: "map",
      propertyModal: {},
      displayPropertyModal: false,
      filter: false,
      edit: false,
    };
  }
  async updateProperties(updatedProperty) {
    const updatedProperties = this.state.properties.map((property) => {
      if (property.id === updatedProperty.id) {
        return updatedProperty;
      }
      return property;
    });
    const updatedPropertiesBackup = this.state.propertiesBackup.map(
      (property) => {
        if (property.id === updatedProperty.id) {
          return updatedProperty;
        }
        return property;
      }
    );
    try {
      await updateProperty(updatedProperty.id, updatedProperty);
    } catch (error) {
      console.error("Error updating property:", error);
    }
    this.setState({
      properties: updatedProperties,
      propertiesBackup: updatedPropertiesBackup,
    });
  }

  async updatePropertyFloor(propertyId, floorId, updatedFloor) {
    try {
      const propertyIndex = this.state.properties.findIndex(
        (property) => property.id === propertyId
      );
      if (propertyIndex === -1) {
        throw new Error("Property not found");
      }
      const propertyToUpdate = this.state.properties[propertyIndex];
      const floorIndex = propertyToUpdate.floors.findIndex(
        (floor) => floor.id === floorId
      );
      if (floorIndex === -1) {
        throw new Error("Floor not found");
      }
      const updatedFloors = [...propertyToUpdate.floors];
      updatedFloors[floorIndex] = updatedFloor;
      const updatedProperty = { ...propertyToUpdate, floors: updatedFloors };
      const updatedProperties = [...this.state.properties];
      updatedProperties[propertyIndex] = updatedProperty;
      this.setState({
        properties: updatedProperties,
      });
      try {
        await updateFloor(updatedFloor.id, updatedFloor);
      } catch (error) {
        console.error("Error updating floor:", error);
      }
    } catch (error) {
      console.error("Error updating property floor:", error);
    }
  }
  async hidePropertyModal() {
    this.setState({ displayPropertyModal: false, propertyModal: {} });
  }

  async displayPropertyModal(property) {
    this.setState({ propertyModal: property, displayPropertyModal: true });
  }

  async selectAllProperties(value) {
    try {
      this.setState({ selectedAllLoading: true });
      const updatedProperties = await Promise.all(
        this.state.properties.map((property) =>
          updateProperty(property.id, { selected: value })
        )
      );
      this.setState({
        properties: updatedProperties,
        selectedAllLoading: false,
      });
    } catch (error) {
      console.error("Error:", error);
      this.setState({ selectedAllLoading: false });
    }
  }

  async selectProperty(propertyId, value) {
    try {
      const property = await updateProperty(propertyId, { selected: value });
      const updatedProperties = this.state.properties.map((prop) => {
        if (prop.id === propertyId) {
          return property;
        } else {
          return prop;
        }
      });

      const updatedPropertiesBackup = this.state.propertiesBackup.map(
        (prop) => {
          if (prop.id === propertyId) {
            return property;
          } else {
            return prop;
          }
        }
      );

      this.setState({
        properties: updatedProperties,
        propertiesBackup: updatedPropertiesBackup,
      });
      return property;
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async componentDidMount() {
    await this.getProperties();
  }

  renderView() {
    switch (this.state.view) {
      case "list":
        return (
          <TableView
            headquarters={this.state.headquarters}
            userMode={this.props.userMode}
            edit={this.state.edit}
            updateProperties={this.updateProperties.bind(this)}
            updatePropertyFloor={this.updatePropertyFloor.bind(this)}
            displayPropertyModal={this.displayPropertyModal.bind(this)}
            selectProperty={this.selectProperty.bind(this)}
            properties={this.state.properties}
          ></TableView>
        );
      case "map":
        return (
          <MapView
            headquarters={this.state.headquarters}
            userMode={this.props.userMode}
            displayPropertyModal={this.displayPropertyModal.bind(this)}
            selectProperty={this.selectProperty.bind(this)}
            properties={this.state.properties}
          ></MapView>
        );
      case "chart":
        return (
          <ChartView
            headquarters={this.state.headquarters}
            userMode={this.props.userMode}
            displayPropertyModal={this.displayPropertyModal.bind(this)}
            properties={this.state.properties}
          ></ChartView>
        );
    }
  }

  filterProperties = (
    floorSizeRange,
    postalCode, // Parameter name changed from division to postalCode
    officePriceRange,
    propertyState, // Now an array
    maxDistanceToTransport,
    selected
  ) => {
    const filteredProperties = this.state.propertiesBackup.filter(
      (property) => {
        const meetsFloorSize =
          floorSizeRange[0] !== "" && floorSizeRange[1] !== ""
            ? property.floor_size >= floorSizeRange[0] &&
              property.floor_size <= floorSizeRange[1]
            : true;
        const meetsPostalCode = // Logic changed to filter by postalCode
          postalCode !== "" ? property.postal_code === postalCode : true;
        const meetsOfficePrice =
          officePriceRange[0] !== "" && officePriceRange[1] !== ""
            ? property.office_price?.some(
                (price) =>
                  price >= officePriceRange[0] && price <= officePriceRange[1]
              )
            : true;
        const meetsPropertyState = // Adjusted for array of states
          propertyState.length > 0 && !propertyState.includes("Indifférent")
            ? propertyState.includes(property.state)
            : true;
        const meetsMaxDistanceToTransport =
          maxDistanceToTransport !== "" && maxDistanceToTransport !== undefined
            ? property.public_transports?.some(
                (transport) =>
                  transport.distance_to_property_minutes <=
                  maxDistanceToTransport
              )
            : true;
        const meetsSelected =
          selected !== "Indifférent" ? property.selected == selected : true;

        return (
          meetsFloorSize &&
          meetsPostalCode &&
          meetsOfficePrice &&
          meetsPropertyState &&
          meetsMaxDistanceToTransport &&
          meetsSelected
        );
      }
    );

    this.setState({ properties: filteredProperties });
  };
  async getProperties() {
    try {
      this.setState({ loading: true });
      const properties = await getPropertiesByProject(this.props.project.id);
      const headquarters = await fetchProjectHeadquarters(
        this.props.project.id
      );

      this.setState({
        headquarters,
        properties: properties.sort((a, b) => a.id - b.id),
        loading: false,
        propertiesBackup: properties,
      });
    } catch (error) {
      console.error("Error:", error);
      this.setState({ loading: false });
    }
  }

  allPropertiesSelected() {
    return this.state.properties.every((property) => property.selected);
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,

          position: "relative",
        }}
      >
        <div
          className="p-2"
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            position: "relative",
          }}
        >
          <div
            className="mb-3"
            style={{
              width: "100%",
              flexDirection: "column",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Row style={{ width: "100%" }}>
              <Col
                md={6}
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <span
                  style={{
                    color: colors.blue,
                    fontSize: fontSizes.large,
                    fontFamily: "Montserrat",
                    fontWeight: fontWeights.bold,
                  }}
                >
                  Immobilier
                </span>
              </Col>

              <Col
                xs={12}
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: 0,
                }}
              >
                {!this.props.userMode && (
                  <Spring
                    opacity={this.state.view == "list" ? 1 : 0}
                    backgroundColor={
                      this.state.edit ? colors.brown : colors.lightBlue
                    }
                  >
                    {(props) => (
                      <animated.div
                        onClick={() =>
                          this.setState({ edit: !this.state.edit })
                        }
                        className="me-3"
                        style={{
                          cursor: "pointer",
                          width: 60,
                          height: 40,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative",
                          borderRadius: 10,
                          backgroundColor: props.backgroundColor,
                          opacity: props.opacity,
                        }}
                      >
                        <PenFill size={24} color={colors.white}></PenFill>
                      </animated.div>
                    )}
                  </Spring>
                )}

                <Spring
                  backgroundColor={
                    this.state.filter ? colors.brown : colors.lightBlue
                  }
                >
                  {(props) => (
                    <animated.div
                      onClick={() =>
                        this.setState({ filter: !this.state.filter })
                      }
                      className="me-3"
                      style={{
                        cursor: "pointer",
                        width: 60,
                        height: 40,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        borderRadius: 10,
                        backgroundColor: props.backgroundColor,
                      }}
                    >
                      <Filter size={24} color={colors.white}></Filter>
                    </animated.div>
                  )}
                </Spring>
                <div
                  style={{
                    width: 180,
                    height: 40,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Spring
                    left={
                      this.state.view == "list"
                        ? 0
                        : this.state.view == "map"
                        ? 60
                        : 120
                    }
                  >
                    {(props) => (
                      <animated.div
                        style={{
                          ...props,
                          position: "absolute",
                          top: 0,
                          backgroundColor: colors.lightBlue,
                          borderRadius: 10,
                          height: 40,
                          width: 60,
                        }}
                      ></animated.div>
                    )}
                  </Spring>
                  <div
                    onClick={() => this.setState({ view: "list" })}
                    style={{
                      cursor: "pointer",

                      height: 40,
                      width: 60,
                      display: "flex",
                      backgroundColor: "blue",
                      backgroundColor: colors.blue,
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ListUl
                      size={24}
                      color={colors.white}
                      style={{ zIndex: 2 }}
                    ></ListUl>
                  </div>
                  <div
                    onClick={() => this.setState({ view: "map" })}
                    style={{
                      cursor: "pointer",

                      height: 40,
                      width: 60,
                      backgroundColor: "blue",
                      backgroundColor: colors.blue,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MapFill
                      style={{ zIndex: 2 }}
                      size={24}
                      color={colors.white}
                    ></MapFill>
                  </div>
                  <div
                    onClick={() => this.setState({ view: "chart" })}
                    style={{
                      cursor: "pointer",
                      height: 40,
                      width: 60,
                      backgroundColor: "blue",
                      backgroundColor: colors.blue,
                      borderBottomRightRadius: 10,
                      borderTopRightRadius: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <BarChartFill
                      style={{ zIndex: 2 }}
                      size={24}
                      color={colors.white}
                    ></BarChartFill>
                  </div>
                </div>
                {!this.props.userMode && (
                  <Spring
                    backgroundColor={
                      this.allPropertiesSelected() ? colors.brown : colors.white
                    }
                  >
                    {(props) => (
                      <div
                        onClick={() => {
                          if (!this.state.selectedAllLoading)
                            this.selectAllProperties(
                              !this.allPropertiesSelected()
                            );
                        }}
                        className="ms-3"
                        // onClick={() => this.selectProperty(property)}
                        style={{
                          cursor: "pointer",
                          width: 40,
                          height: 40,

                          borderRadius: 20,

                          backgroundColor: colors.background,
                          display: "flex",
                          justifyContent: "center",
                          position: "relative",
                          alignItems: "center",
                          boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.10)",
                        }}
                      >
                        <animated.div
                          style={{
                            display: "flex",
                            width: "70%",
                            height: "70%",
                            borderRadius: "50%",

                            justifyContent: "center",
                            alignItems: "center",
                            ...props,
                          }}
                        ></animated.div>

                        {this.state.selectedAllLoading ? (
                          <Spinner
                            style={{ position: "absolute" }}
                            variant="light"
                            size="sm"
                          ></Spinner>
                        ) : (
                          <CheckLg
                            style={{ position: "absolute" }}
                            size={"50%"}
                            color={colors.background}
                          ></CheckLg>
                        )}
                      </div>
                    )}
                  </Spring>
                )}
              </Col>
            </Row>
          </div>

          <PropertyFilter
            show={this.state.filter}
            filterProperties={this.filterProperties.bind(this)}
          ></PropertyFilter>

          <span
            style={{
              color: colors.lightBlue,
              fontSize: fontSizes.medium,
              fontFamily: "Montserrat",
            }}
          >
            <span style={{ fontWeight: fontWeights.bold }}>
              {this.state.properties.length}
            </span>{" "}
            biens correspondant(s)
          </span>

          <div
            style={{
              display: "flex",
              flex: 1,
              height: "100%",
              width: "100%",
            }}
          >
            {this.renderView()}
          </div>
        </div>
        {this.state.displayPropertyModal && (
          <PropertyModal
            hidePropertyModal={this.hidePropertyModal.bind(this)}
            property={this.state.propertyModal}
          ></PropertyModal>
        )}
      </div>
    );
  }
}

export default withRouter(Properties);
