import React, { Component } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { createProject, getProjects } from "../api";
import Card from "react-bootstrap/Card";
import { withRouter } from "../withRouter";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import NavBar from "./NavBar";
import { colors } from "../colors";
import { fontWeights } from "../fontWeights";
import { fontSizes } from "../fontSizes";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import Button from "./Button";

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      isLoading: true,
    };
    this.navigateToProject = this.navigateToProject.bind(this);
  }

  componentDidMount() {
    this.fetchProjects();
  }

  navigateToProject = (projectId) => {
    this.props.navigate(`/projects/${projectId}`, {
      state: { userMode: false },
    });
  };

  fetchProjects = async () => {
    try {
      this.setState({ isLoading: true });
      const projects = await getProjects();
      projects.sort((a, b) => a.id - b.id);
      this.setState({ projects, isLoading: false });
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  handleProjectName = (e) => {
    this.setState({ projectName: e.target.value });
  };

  handleProjectCreate = async () => {
    try {
      const data = { name: "Nouveau projet" };
      const newProjet = await createProject(data);
      const newProjects = this.state.projects;
      newProjects.push(newProjet);
      this.setState({ projects: newProjects });
    } catch {}
  };
  render() {
    return (
      <div
        style={{
          backgroundColor: "red",
          display: "flex",
          width: "100vw",
          height: "100vh",
          backgroundColor: colors.background,
          flexDirection: "column",
        }}
      >
        <NavBar />
        <Container
          className="mt-4"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div
            style={{
              position: "relative",
              display: "flex",
              height: 55,
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: 55,
                height: 55,
                borderRadius: 55 / 2,
                backgroundColor: colors.brown,
                position: "absolute",
                zIndex: 0,
                top: 0,
                left: -55 / 3,
              }}
            ></div>
            <span
              style={{
                zIndex: 1,
                color: colors.blue,
                fontWeight: fontWeights.black,
                fontSize: fontSizes.large,
                fontFamily: "Montserrat",

                textAlign: "left",
              }}
            >
              Projets
            </span>
          </div>

          <div
            style={{
              display: "flex",
              height: "80%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div
              className="mt-2"
              style={{
                width: "80%",
                height: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                overflowY: "scroll",
                maxHeight: 500,
              }}
            >
              {this.state.isLoading ? (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner></Spinner>
                </div>
              ) : (
                this.state.projects.map((project, i) => (
                  <div
                    key={i}
                    style={{
                      width: 200,
                      height: 200,
                      borderRadius: 10,
                      borderWidth: 1,
                      borderColor: colors.lightBlue,
                      borderStyle: "solid",
                      position: "relative",
                      margin: 10,
                    }}
                  >
                    <Row>
                      <Col md={12} style={{ display: "flex" }}>
                        <span
                          className="mt-2 mx-2"
                          style={{
                            fontFamily: "Montserrat",
                            fontWeight: fontWeights.bold,
                            fontSize: fontSizes.medium,
                            color: colors.blue,
                            textAlign: "left",
                          }}
                        >
                          {project.name}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} style={{ display: "flex" }}></Col>
                    </Row>
                    <IoIosArrowDroprightCircle
                      onClick={() => this.navigateToProject(project.id)}
                      color={colors.brown}
                      size={22}
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: 10,
                        bottom: 10,
                      }}
                    />
                  </div>
                ))
              )}
            </div>
          </div>
          <div
            className="mt-4"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={this.handleProjectCreate.bind(this)}
              title={"Nouveau projet"}
            ></Button>
          </div>
        </Container>
      </div>
    );
  }
}

export default withRouter(Projects);
