import * as React from "react";
const PinBlueHome = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={35}
    fill="none"
    {...props}
  >
    <path
      fill="#2B3D5C"
      d="M13.125 35S26.25 22.562 26.25 13.125a13.125 13.125 0 1 0-26.25 0C0 22.562 13.125 35 13.125 35Zm0-15.313a6.562 6.562 0 1 1 0-13.124 6.562 6.562 0 0 1 0 13.125Z"
    />
    <ellipse cx={13.5} cy={13.125} fill="#2B3D5C" rx={8.75} ry={10.938} />
    <path
      fill="#fff"
      d="m18.448 12.962-5.15-5.145a.399.399 0 0 0-.564 0l-5.149 5.145a.801.801 0 0 0 .565 1.366h.543V18a.4.4 0 0 0 .4.4h3.123v-2.8h1.4v2.8h3.324a.4.4 0 0 0 .4-.4v-3.672h.543a.8.8 0 0 0 .565-1.366Z"
    />
  </svg>
);
export default PinBlueHome;
