import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { colors } from "../colors";
import { fontSizes } from "../fontSizes";
import { fontWeights } from "../fontWeights";

class PropertyFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      floorSizeRange: ["", ""],
      postalCode: "",
      officePriceRange: ["", ""],
      propertyState: [],
      maxDistanceToTransport: "",
      selected: "Indifférent",
    };
  }

  handleFloorSizeChange = (index, value) => {
    let floorSizeRange = [...this.state.floorSizeRange];
    floorSizeRange[index] = value ? Number(value) : "";
    this.setState({ floorSizeRange }, () => {
      this.props.filterProperties(
        this.state.floorSizeRange,
        this.state.postalCode,
        this.state.officePriceRange,
        this.state.propertyState,
        this.state.maxDistanceToTransport,
        this.state.selected
      );
    });
  };

  handlePostalCodeChange = (event) => {
    // Changed method name and functionality
    this.setState({ postalCode: event.target.value }, () => {
      this.props.filterProperties(
        this.state.floorSizeRange,
        this.state.postalCode,
        this.state.officePriceRange,
        this.state.propertyState,
        this.state.maxDistanceToTransport,
        this.state.selected
      );
    });
  };

  handleOfficePriceChange = (index, value) => {
    let officePriceRange = [...this.state.officePriceRange];
    officePriceRange[index] = value ? Number(value) : "";
    this.setState({ officePriceRange }, () => {
      this.props.filterProperties(
        this.state.floorSizeRange,
        this.state.postalCode,
        this.state.officePriceRange,
        this.state.propertyState,
        this.state.maxDistanceToTransport,
        this.state.selected
      );
    });
  };

  handleStateChange = (event) => {
    const value = event.target.value;
    this.setState(
      (prevState) => {
        const newPropertyState = prevState.propertyState.includes(value)
          ? prevState.propertyState.filter((state) => state !== value) // Remove the state if it's already in the array
          : [...prevState.propertyState, value]; // Add the state if it's not in the array
        return { propertyState: newPropertyState };
      },
      () => {
        this.props.filterProperties(
          this.state.floorSizeRange,
          this.state.postalCode,
          this.state.officePriceRange,
          this.state.propertyState,
          this.state.maxDistanceToTransport,
          this.state.selected
        );
      }
    );
  };
  handleMaxDistanceToTransportChange = (event) => {
    this.setState(
      {
        maxDistanceToTransport: event.target.value
          ? Number(event.target.value)
          : "",
      },
      () => {
        this.props.filterProperties(
          this.state.floorSizeRange,
          this.state.postalCode,
          this.state.officePriceRange,
          this.state.propertyState,
          this.state.maxDistanceToTransport,
          this.state.selected
        );
      }
    );
  };

  handleSelectedChange = (event) => {
    this.setState({ selected: event.target.value }, () => {
      this.props.filterProperties(
        this.state.floorSizeRange,
        this.state.postalCode,
        this.state.officePriceRange,
        this.state.propertyState,
        this.state.maxDistanceToTransport,
        this.state.selected === "Oui"
          ? true
          : this.state.selected === "Non"
          ? false
          : "Indifférent"
      );
    });
  };
  render() {
    if (this.props.show)
      return (
        <Form>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label
                style={{
                  color: colors.lightBlue,
                  fontSize: fontSizes.medium,
                  fontFamily: "Montserrat",
                  fontWeight: fontWeights.normal,
                  textAlign: "left",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                Surface (m²){" "}
              </Form.Label>
              <Row>
                <Col>
                  <Form.Control
                    type="number"
                    value={this.state.floorSizeRange[0]}
                    placeholder="Surface min."
                    onChange={(e) =>
                      this.handleFloorSizeChange(0, e.target.value)
                    }
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    value={this.state.floorSizeRange[1]}
                    placeholder="Surface max."
                    onChange={(e) =>
                      this.handleFloorSizeChange(1, e.target.value)
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label
                style={{
                  color: colors.lightBlue,
                  fontSize: fontSizes.medium,
                  fontFamily: "Montserrat",
                  fontWeight: fontWeights.normal,
                  textAlign: "left",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                Loyer facial (€/m²/an)
              </Form.Label>
              <Row>
                <Col>
                  <Form.Control
                    type="number"
                    value={this.state.officePriceRange[0]}
                    placeholder="Loyer min."
                    onChange={(e) =>
                      this.handleOfficePriceChange(0, e.target.value)
                    }
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    value={this.state.officePriceRange[1]}
                    placeholder="Loyer max."
                    onChange={(e) =>
                      this.handleOfficePriceChange(1, e.target.value)
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label
                style={{
                  color: colors.lightBlue,
                  fontSize: fontSizes.medium,
                  fontFamily: "Montserrat",
                  fontWeight: fontWeights.normal,
                  textAlign: "left",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                Distance maximale aux transports (en min. à pied)
              </Form.Label>
              <Form.Control
                type="number"
                value={this.state.maxDistanceToTransport}
                onChange={this.handleMaxDistanceToTransportChange}
                placeholder="Distance max."
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label
                style={{
                  color: colors.lightBlue,
                  fontSize: fontSizes.medium,
                  fontFamily: "Montserrat",
                  fontWeight: fontWeights.normal,
                  textAlign: "left",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                Code Postal
              </Form.Label>
              <Form.Control
                type="text" // Changed type to text for postal code input
                value={this.state.postalCode}
                onChange={this.handlePostalCodeChange}
                placeholder="Code Postal"
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label
                style={{
                  color: colors.lightBlue,
                  fontSize: fontSizes.medium,
                  fontFamily: "Montserrat",
                  fontWeight: fontWeights.normal,
                  textAlign: "left",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                État
              </Form.Label>
              {[
                "Neuf",
                "Rénové",
                "Restructuré",
                "Etat d'usage",
                "Non spécifié",
              ].map((state) => (
                <Form.Check
                  type="checkbox"
                  label={state}
                  value={state}
                  key={state}
                  checked={this.state.propertyState.includes(state)}
                  onChange={this.handleStateChange}
                />
              ))}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label
                style={{
                  color: colors.lightBlue,
                  fontSize: fontSizes.medium,
                  fontFamily: "Montserrat",
                  fontWeight: fontWeights.normal,
                  textAlign: "left",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                Sélectionné
              </Form.Label>
              <Form.Select
                value={this.state.selected}
                onChange={this.handleSelectedChange}
              >
                <option value="Indifférent">Indifférent</option>
                <option value="Oui">Oui</option>
                <option value="Non">Non</option>
              </Form.Select>
            </Form.Group>
          </Row>

          <Row className="mb-3"></Row>
        </Form>
      );
  }
}

export default PropertyFilter;
