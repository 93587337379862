import React, { Component } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { colors } from "../colors";
import { fontWeights } from "../fontWeights";
import { fontSizes } from "../fontSizes";
import { withRouter } from "../withRouter";

class NavBar extends Component {
  render() {
    return (
      <div
        style={{
          height: 64,
          width: "100%",
          backgroundColor: colors.blue,
          display: "flex",
        }}
      >
        <Container fluid className="px-4">
          <Row className="align-items-center" style={{ height: "100%" }}>
            <Col
              md={8}
              className="d-flex align-items-center"
              style={{ height: "100%" }}
            >
              <Image
                src={require("../assets/logo.png")}
                style={{ height: "60%" }}
              />
              {!this.props.userMode && (
                <div
                  className="ms-3 d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.props.navigate("/projects")}
                >
                  <div
                    className="me-2"
                    style={{
                      width: 6,
                      height: 6,
                      borderRadius: 3,
                      border: "white 1px solid",
                    }}
                  ></div>
                  <span
                    style={{
                      fontFamily: "Montserrat",
                      color: colors.white,
                      fontWeight: fontWeights.medium,
                    }}
                  >
                    Projets
                  </span>
                </div>
              )}
              {this.props.userMode && (
                <Button
                  onClick={() => this.props.navigate("/login")}
                  style={{
                    marginLeft: "auto",
                    backgroundColor: colors.lightBlue,
                    color: colors.white,
                    border: "none",
                    padding: "8px 16px",
                    borderRadius: "4px",
                    fontFamily: "Montserrat",
                    fontWeight: fontWeights.medium,
                  }}
                >
                  Admin
                </Button>
              )}
            </Col>
            <Col md={4} className="d-none d-md-flex justify-content-end">
              <span
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: fontWeights.black,
                  color: colors.white,
                  fontSize: fontSizes.large,
                }}
              >
                <span style={{ color: colors.lightBlue }}>IA</span>
                {"n "}
                <span style={{ fontWeight: fontWeights.bold }}>Platform</span>
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(NavBar);
