import React, { Component } from "react";
import { getProject } from "../api";

import { withRouter } from "../withRouter";

import SideBar from "./SideBar";
import { colors } from "../colors";
import NavBar from "./NavBar";
import ProjectManagement from "./ProjectManagement";
import Documents from "./Documents";
import Properties from "./Properties";
import Exports from "./Exports";

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: {},
      loading: true,
      screenNameSelected: "Gestion du projet",
      childIdSelected: "",
    };
  }

  updateProject = (project) => {
    this.setState((prevState) => ({
      project: {
        ...prevState.project,
        ...project,
      },
    }));
  };

  renderScreen = () => {
    if (this.props.location.state.userMode) {
      return (
        <Properties
          userMode={true}
          project={{ id: this.props.params.project_id }}
        ></Properties>
      );
    }
    if (this.state.project)
      switch (this.state.screenNameSelected) {
        case "Gestion du projet":
          return (
            <ProjectManagement
              updateProject={this.updateProject.bind(this)}
              project={this.state.project}
            ></ProjectManagement>
          );
        case "Documents":
          return <Documents project={this.state.project}></Documents>;
        case "Immobilier":
          return <Properties project={this.state.project}></Properties>;
        case "Export":
          return <Exports project={this.state.project}></Exports>;
        default:
          return null;
      }
    else return null;
  };

  async loadProject() {
    try {
      const project = await getProject(this.props.params.project_id);
      this.setState({ project, loading: false });
    } catch (error) {
      console.error("Error:", error);
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    if (!this.props.location.state.userMode) {
      this.loadProject();
    }
  }

  changeScreen = (screenName) => {
    this.setState({ screenNameSelected: screenName });
  };

  changeChild = (childId) => {
    this.setState({ childIdSelected: childId });
  };

  render() {
    return (
      <div
        style={{
          backgroundColor: "red",
          width: "100vw",
          height: "100vh",
          flex: 1,
          overflow: "hidden",
          maxHeight: "100vh",
          backgroundColor: colors.background,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <NavBar userMode={this.props.location.state.userMode} />
        <div
          style={{
            display: "flex",
            width: "100%",
            flex: 1,
            height: "calc(100vh - 56px)",
          }}
        >
          {!this.props.location.state.userMode && (
            <SideBar
              changeScreen={this.changeScreen.bind(this)}
              changeChild={this.changeChild.bind(this)}
              project={this.state.project}
            ></SideBar>
          )}

          <div
            className="p-3"
            style={{
              display: "flex",
              width: "100%",
              flex: 1,
            }}
          >
            {this.renderScreen()}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Project);
