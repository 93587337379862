import React, { Component } from "react";
import { colors } from "../colors";
import { fontSizes } from "../fontSizes";
import { fontWeights } from "../fontWeights";
import TextInput from "./TextInput";
import { deleteProject, updateProject } from "../api";
import Button from "./Button";
import { withRouter } from "../withRouter";
import { Row, Col } from "react-bootstrap";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { HouseFill } from "react-bootstrap-icons";
import PinBlueHome from "../assets/PinBlueHomeSVG";

// const AnyReactComponent = ({ text }) => (
// <div
//   style={{
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//   }}
//   onMouseEnter={() => {
//     console.log('enter');
//   }}
// >
//   <PinBlue></PinBlue>
//   <span
//     style={{
//       color: colors.blue,
//       fontSize: fontSizes.small,
//       fontFamily: 'Montserrat',
//       fontWeight: fontWeights.bold,
//     }}
//   >
//     Siege
//   </span>
// </div>
// );

// class MapComponent extends Component {
//   render() {
//     return (
//       <div
//         style={{
//           height: '400px',
//           width: '100%',
//           borderRadius: 10,
//           overflow: 'hidden',
//         }}
//       >
//         <GoogleMapReact
//           // yesIWantToUseGoogleMapApiInternals={true}
//           bootstrapURLKeys={{
//             key: 'AIzaSyDGdKl24Fpq8cml_XXlQUXAhmNMtnL3XGI',
//             libraries: ['places'],
//           }}
//           center={{ lat: this.props.lat, lng: this.props.long }}
//           defaultZoom={6}
//         >
//           <AnyReactComponent
//             lat={this.props.lat}
//             lng={this.props.long}
//             text='My Marker'
//           />
//         </GoogleMapReact>
//       </div>
//     );
//   }
// }

import { APIProvider, Map, AdvancedMarker } from "@vis.gl/react-google-maps";

class MapComponent extends Component {
  handleMapLoad = (map) => {
    const { google } = window;
    const transitLayer = new google.maps.TransitLayer();

    transitLayer.setMap(map);
  };
  render() {
    return (
      <div style={{ height: "400px", borderRadius: 10, overflow: "hidden" }}>
        {this.props.lat ? (
          <APIProvider apiKey="AIzaSyDGdKl24Fpq8cml_XXlQUXAhmNMtnL3XGI">
            <Map
              ref={this.mapRef}
              disableDefaultUI={true}
              zoom={15}
              center={{ lat: this.props.lat, lng: this.props.long }}
              mapId={"16c4311bb211988c"}
              onLoad={this.handleMapLoad}
            >
              <AdvancedMarker
                position={{ lat: this.props.lat, lng: this.props.long }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PinBlueHome></PinBlueHome>
                  <span
                    style={{
                      color: colors.blue,
                      fontSize: fontSizes.small,
                      fontFamily: "Montserrat",
                      fontWeight: fontWeights.bold,
                    }}
                  >
                    Site
                  </span>
                </div>
              </AdvancedMarker>
            </Map>
          </APIProvider>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              borderRadius: 10,
              border: `1px solid ${colors.lightBlue}`,
            }}
          >
            <span
              style={{
                color: colors.blue,
                fontSize: fontSizes.small,
                fontFamily: "Montserrat",
                fontWeight: fontWeights.bold,
              }}
            >
              Aucune adresse renseignée
            </span>
          </div>
        )}
      </div>
    );
  }
}

class ProjectManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newProjectName: this.props.project?.name,
      headQuarterAddress: this.props.project?.headquarter_address,
      headQuarterPrice: this.props.project?.headquarter_price,
      headQuarterLat: this.props.project?.headquarter_lat,
      headQuarterLong: this.props.project?.headquarter_long,
      headQuarterCharges: this.props.project?.headquarter_charges,
      headQuarterSurface: this.props.project?.headquarter_surface,
    };
  }

  changeProjectName = (e) => {
    this.setState({ newProjectName: e.target.value });
  };

  updateProject = async () => {
    const project = this.props.project;
    const updatedProject = {};

    if (this.state.newProjectName !== project?.name) {
      updatedProject.name = this.state.newProjectName;
    }
    if (this.state.headQuarterAddress !== project?.headquarter_address) {
      updatedProject.headquarter_address = this.state.headQuarterAddress;
    }
    if (this.state.headQuarterPrice !== project?.headquarter_price) {
      updatedProject.headquarter_price = this.state.headQuarterPrice;
    }

    if (this.state.headQuarterCharges !== project?.headquarter_charges)
      updatedProject.headquarter_charges = this.state.headQuarterCharges;
    if (this.state.headQuarterSurface !== project?.headquarter_surface)
      updatedProject.headquarter_surface = this.state.headQuarterSurface;

    this.props.updateProject(updatedProject);
    try {
      const projectUpdated = await updateProject(project.id, updatedProject);
      this.setState({
        headQuarterLat: projectUpdated.headquarter_lat,
        headQuarterLong: projectUpdated.headquarter_long,
      });
      // this.props.loadProject();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.project?.id !== this.props.project?.id) {
      this.setState({
        newProjectName: this.props.project?.name,
        headQuarterAddress: this.props.project?.headquarter_address,
        headQuarterPrice: this.props.project?.headquarter_price,
        headQuarterLat: this.props.project?.headquarter_lat,
        headQuarterLong: this.props.project?.headquarter_long,
        headQuarterCharges: this.props.project?.headquarter_charges,
        headQuarterSurface: this.props.project?.headquarter_surface,
      });
    }
  }

  deleteProject = async () => {
    try {
      await deleteProject(this.props.project.id);
      this.props.navigate("/projects");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  officeAddress = (headQuarterAddress) => {
    this.setState(
      { headQuarterAddress: headQuarterAddress.value.description },
      () => {
        this.updateProject();
      }
    );
  };

  changePrice = (headQuarterPrice) => {
    this.setState({ headQuarterPrice: headQuarterPrice.target.value });
  };

  changeCharges = (headQuarterPrice) => {
    this.setState({ headQuarterCharges: headQuarterPrice.target.value });
  };

  changeSurface = (headQuarterSurface) => {
    this.setState({ headQuarterSurface: headQuarterSurface.target.value });
  };
  render() {
    return (
      <div
        className="p-2"
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          overflowY: "auto",
        }}
      >
        <div
          className="mb-3"
          style={{
            width: "100%",
            flexDirection: "column",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <span
            style={{
              color: colors.blue,
              fontSize: fontSizes.large,
              fontFamily: "Montserrat",
              fontWeight: fontWeights.bold,
            }}
          >
            Gestion du projet
          </span>
          <div
            className="mt-3"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <span
              style={{
                color: colors.blue,
                fontSize: fontSizes.medium,
                fontFamily: "Montserrat",
                fontWeight: fontWeights.bold,
              }}
            >
              Nom du projet
            </span>
            <TextInput
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  this.updateProject();
                }
              }}
              onBlur={this.updateProject}
              placeholder="Nom"
              value={this.state.newProjectName}
              onChange={this.changeProjectName.bind(this)}
            ></TextInput>
          </div>
          <div
            className="mt-3"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <span
              className="mb-1"
              style={{
                color: colors.blue,
                fontSize: fontSizes.medium,
                fontFamily: "Montserrat",
                fontWeight: fontWeights.bold,
              }}
            >
              Site du client
            </span>
            <div style={{ width: "100%" }}>
              <Row>
                <Col md={6} xs={12} className="mb-2">
                  <MapComponent
                    lat={this.state.headQuarterLat}
                    long={this.state.headQuarterLong}
                  ></MapComponent>
                </Col>
                <Col
                  md={6}
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <span
                    className="mb-1"
                    style={{
                      color: colors.blue,
                      fontSize: fontSizes.medium,
                      fontFamily: "Montserrat",
                      fontWeight: fontWeights.bold,
                    }}
                  >
                    Adresse
                  </span>
                  <div
                    className="mb-4"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                      }}
                      className="mb-1"
                    >
                      <GooglePlacesAutocomplete
                        style={{ borderColor: colors.blue, width: "100%" }}
                        apiKey="AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY"
                        selectProps={{
                          value: this.state.headQuarterAddress,
                          onChange: this.officeAddress,
                          placeholder: "Entrez une adresse", // Added placeholder property
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: colors.blue,
                        fontSize: fontSizes.medium,
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.medium,
                        textAlign: "left",
                      }}
                    >
                      <HouseFill color={colors.lightBlue}></HouseFill>{" "}
                      {this.state.headQuarterAddress}
                    </span>
                  </div>
                  <span
                    className="mb-1"
                    style={{
                      color: colors.blue,
                      fontSize: fontSizes.medium,
                      fontFamily: "Montserrat",
                      fontWeight: fontWeights.bold,
                    }}
                  >
                    Loyer facial (€/m²/an HTHC)
                  </span>
                  <div style={{ display: "flex" }}>
                    <TextInput
                      type="number"
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.updateProject();
                        }
                      }}
                      onBlur={this.updateProject}
                      placeholder="Montant (€/m²/an HTHC)"
                      value={this.state.headQuarterPrice}
                      onChange={this.changePrice.bind(this)}
                    ></TextInput>
                    <span
                      style={{
                        color: colors.blue,
                        fontSize: fontSizes.medium,
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.medium,
                      }}
                    >
                      €
                    </span>
                  </div>
                  <span
                    className="mt-3"
                    style={{
                      color: colors.blue,
                      fontSize: fontSizes.medium,
                      fontFamily: "Montserrat",
                      fontWeight: fontWeights.bold,
                    }}
                  >
                    Charges (€/m²/an HTHC)
                  </span>
                  <div style={{ display: "flex" }}>
                    <TextInput
                      type="number"
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.updateProject();
                        }
                      }}
                      onBlur={this.updateProject}
                      placeholder="Montant (€/m²/an HTHC)"
                      value={this.state.headQuarterCharges}
                      onChange={this.changeCharges.bind(this)}
                    ></TextInput>
                    <span
                      style={{
                        color: colors.blue,
                        fontSize: fontSizes.medium,
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.medium,
                      }}
                    >
                      €
                    </span>
                  </div>
                  <span
                    className="mt-3"
                    style={{
                      color: colors.blue,
                      fontSize: fontSizes.medium,
                      fontFamily: "Montserrat",
                      fontWeight: fontWeights.bold,
                    }}
                  >
                    Surface (m²)
                  </span>
                  <div style={{ display: "flex" }}>
                    <TextInput
                      type="number"
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.updateProject();
                        }
                      }}
                      onBlur={this.updateProject}
                      placeholder="Surface (m²)"
                      value={this.state.headQuarterSurface}
                      onChange={this.changeSurface.bind(this)}
                    ></TextInput>
                    <span
                      style={{
                        color: colors.blue,
                        fontSize: fontSizes.medium,
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.medium,
                      }}
                    >
                      m²
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Button
            onClick={this.deleteProject.bind(this)}
            title={"Supprimer le projet"}
          ></Button>
        </div>
      </div>
    );
  }
}

export default withRouter(ProjectManagement);
