import React from "react";
import { colors } from "../colors";
import { fontSizes } from "../fontSizes";
import { fontWeights } from "../fontWeights";
import { Spring, animated } from "@react-spring/web";
import { Row, Col } from "react-bootstrap";
import { APIProvider, Map, AdvancedMarker } from "@vis.gl/react-google-maps";
import PinBlue from "../assets/PinBlueSVG";

import { CloudArrowDownFill, XCircleFill } from "react-bootstrap-icons";
import { exportPropertySourcePDF } from "../api";
import { BASE_URL } from "../config";

class PropertyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
    };
  }

  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const property = this.props.property;
    return (
      <Spring
        from={{
          paddingTop: "100%",
          opacity: 0,
        }}
        to={{ paddingTop: "0%", opacity: 1 }}
      >
        {({ paddingTop, opacity }) => (
          <animated.div
            style={{
              opacity: opacity,
              paddingTop: paddingTop,
              width: "100%",
              height: "100%",
              position: "absolute",
              backgroundColor: "rgba(0,0,0,0.30)",
              borderRadius: 10,
              display: "flex",
              zIndex: 4,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col
              md={10}
              xs={12}
              style={{
                height: "80%",
                position: "absolute",
                backgroundColor: colors.background,
                borderRadius: 10,
                display: "flex",
                overflow: "hidden",
                flexDirection: "column",
              }}
            >
              <div
                className="p-3"
                style={{
                  display: "flex",
                  width: "100%",
                  height: 200,
                  maxHeight: 200,

                  flexDirection: "column",
                  backgroundColor: colors.blue,
                  flex: 1,
                  position: "relative",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  {property.image_path !== null && (
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      src={BASE_URL + "properties/" + property.id + "/image"}
                    />
                  )}
                </div>
                <XCircleFill
                  onClick={() => {
                    this.props.hidePropertyModal();
                  }}
                  size={20}
                  color={colors.background}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: 10,
                    top: 10,
                    zIndex: 11,
                  }}
                ></XCircleFill>

                <Row style={{ display: "flex", width: "100%", height: "100%" }}>
                  <Col
                    md={4}
                    xs={6}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      height: "100%",
                      zIndex: 101,
                    }}
                  >
                    <span
                      style={{
                        color: colors.white,
                        fontSize: fontSizes.large,
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.bold,
                        textAlign: "left",
                      }}
                    >
                      {property.name}
                    </span>
                    <span
                      style={{
                        textAlign: "left",
                        color: colors.white,
                        fontSize: fontSizes.medium,
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.medium,
                      }}
                    >
                      {property.address}, {property.postal_code},{" "}
                      {property.city}
                    </span>
                  </Col>
                  <Col
                    md={5}
                    xs={6}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                      zIndex: 10,
                    }}
                  >
                    <span
                      style={{
                        color: colors.white,
                        fontSize: fontSizes.large,
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.bold,
                        textAlign: "left",
                      }}
                    >
                      {property.floor_size
                        ? property.floor_size.toLocaleString("fr-FR")
                        : "- "}
                      m²
                    </span>
                    {property.office_price?.length > 0 && (
                      <span
                        style={{
                          textAlign: "left",
                          color: colors.brown,
                          fontSize: fontSizes.large,
                          fontFamily: "Montserrat",
                          fontWeight: fontWeights.bold,
                        }}
                      >
                        {property.office_price
                          .map((price) => price.toLocaleString("fr-FR"))
                          .join(" - ")}
                        €
                        <span
                          style={{
                            textAlign: "left",
                            color: colors.brown,
                            fontSize: fontSizes.medium,
                            fontFamily: "Montserrat",
                            fontWeight: fontWeights.bold,
                          }}
                        >
                          /m²/an
                        </span>
                      </span>
                    )}
                  </Col>
                </Row>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: colors.background,
                  overflowY: "scroll",
                  flex: 5,
                }}
              >
                <Row
                  style={{
                    display: "flex",
                    height: "100%",
                    flex: 1,
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Col
                    md={6}
                    xs={12}
                    style={{
                      padding: 0,
                      display: "flex",
                      maxHeight: "100%",
                      height: this.state.windowWidth <= 576 ? "50%" : "100%",
                      //   height: '100%',
                      flexDirection: "column",
                    }}
                  >
                    <APIProvider apiKey="AIzaSyDGdKl24Fpq8cml_XXlQUXAhmNMtnL3XGI">
                      <Map
                        style={{ borderRadius: 10 }}
                        disableDefaultUI={true}
                        zoom={15}
                        center={{
                          lat: property.lat,
                          lng: property.long,
                        }}
                        mapId={"16c4311bb211988c"}
                      >
                        {property.public_transports.map((pt, index) => (
                          <AdvancedMarker
                            key={index}
                            position={{ lat: pt.lat, lng: pt.long }}
                          >
                            <div
                              style={{
                                height: 50,
                                width: 100,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              {/* <PinBlue color={colors.lightBlue}></PinBlue> */}

                              <span
                                className="mt-2"
                                style={{
                                  position: "absolute",
                                  bottom: -30,
                                  width: "100%",
                                  color: colors.blue,
                                  fontSize: fontSizes.small,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.bold,
                                  position: "absolute",
                                }}
                              >
                                {pt.name}
                                <br />
                                <div style={{ display: "flex" }}>
                                  {pt.lines.map((line) => (
                                    <div
                                      className="me-1"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 3,
                                        width: 20,
                                        color: "#" + line.text_color,
                                        backgroundColor: "#" + line.color,
                                      }}
                                    >
                                      {line.name}
                                    </div>
                                  ))}
                                </div>
                              </span>
                            </div>
                          </AdvancedMarker>
                        ))}
                        <AdvancedMarker
                          position={{ lat: property.lat, lng: property.long }}
                        >
                          <div
                            style={{
                              height: 50,
                              width: 50,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <PinBlue color={colors.lightBlue}></PinBlue>

                            <span
                              className="mt-2"
                              style={{
                                position: "absolute",
                                bottom: -30,
                                color: colors.blue,
                                fontSize: fontSizes.small,
                                fontFamily: "Montserrat",
                                fontWeight: fontWeights.bold,
                                position: "absolute",
                              }}
                            >
                              {property.name}
                            </span>
                          </div>
                        </AdvancedMarker>
                      </Map>
                    </APIProvider>
                  </Col>
                  <Col
                    className="p-2"
                    md={6}
                    xs={12}
                    style={{
                      display: "flex",
                      flex: 1,
                      width: "100%",
                      height: "100%",
                      flexDirection: "column",
                      overflowY: "scroll",
                    }}
                  >
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          color: colors.blue,
                          fontSize: fontSizes.medium,
                          fontFamily: "Montserrat",
                          fontWeight: fontWeights.bold,
                          textAlign: "left",
                        }}
                      >
                        Disponibilité
                        <br />
                        <span
                          style={{
                            color: colors.brown,
                            fontSize: fontSizes.medium,
                            fontFamily: "Montserrat",
                            fontWeight: fontWeights.bold,
                            textAlign: "left",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {property.availability}
                        </span>
                      </span>
                      <span
                        style={{
                          color: colors.blue,
                          fontSize: fontSizes.medium,
                          fontFamily: "Montserrat",
                          fontWeight: fontWeights.bold,
                          textAlign: "right",
                        }}
                      >
                        Etat
                        <br />
                        <span
                          style={{
                            color: colors.brown,
                            fontSize: fontSizes.medium,
                            fontFamily: "Montserrat",
                            fontWeight: fontWeights.bold,
                            textAlign: "left",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {property.state}
                        </span>
                      </span>
                    </div>
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          color: colors.blue,
                          fontSize: fontSizes.medium,
                          fontFamily: "Montserrat",
                          fontWeight: fontWeights.bold,
                          textAlign: "left",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Accès transports
                        <br />
                        <div
                          style={{
                            width: "100%",
                            maxHeight: 100,
                            overflowY: "scroll",
                          }}
                        >
                          {property.public_transports
                            .sort(
                              (a, b) =>
                                a.distance_to_property_minutes -
                                b.distance_to_property_minutes
                            )
                            .slice(0, 10)
                            .map((transport) => (
                              <div
                                className="pb-1"
                                style={{
                                  width: "100%",
                                  //   borderBottom: '1px solid ' + colors.brown,
                                }}
                              >
                                <span
                                  style={{
                                    color: colors.brown,
                                    fontSize: fontSizes.medium,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.bold,
                                    textAlign: "left",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {transport.name} (
                                  <span
                                    style={{
                                      color: colors.lightBlue,
                                      fontSize: fontSizes.medium,
                                      fontFamily: "Montserrat",
                                      fontWeight: fontWeights.bold,
                                      textAlign: "left",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {transport.distance_to_property_minutes} min
                                  </span>
                                  ) <br />
                                  <div style={{ display: "flex" }}>
                                    {transport.lines.map((line) => (
                                      <div
                                        className="me-1"
                                        style={{
                                          fontSize: fontSizes.small,
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          borderRadius: 3,
                                          width: 20,
                                          color: "#" + line.text_color,
                                          backgroundColor: "#" + line.color,
                                        }}
                                      >
                                        {line.name}
                                      </div>
                                    ))}
                                  </div>
                                </span>
                              </div>
                            ))}
                        </div>
                      </span>
                      <span
                        className="mb-2"
                        style={{
                          color: colors.blue,
                          fontSize: fontSizes.medium,
                          fontFamily: "Montserrat",
                          fontWeight: fontWeights.bold,
                          textAlign: "right",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Divibilité
                        <br />
                        <span
                          style={{
                            color: colors.brown,
                            fontSize: fontSizes.medium,
                            fontFamily: "Montserrat",
                            fontWeight: fontWeights.bold,
                            textAlign: "left",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {property.division ? "oui" : "non"}
                        </span>
                      </span>
                    </div>
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          color: colors.blue,
                          fontSize: fontSizes.medium,
                          fontFamily: "Montserrat",
                          fontWeight: fontWeights.bold,
                          textAlign: "left",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Etages
                        <br />
                        <div
                          style={{
                            width: "100%",
                            maxHeight: 100,
                            overflowY: "scroll",
                          }}
                        >
                          {property.floors.map((floor) => (
                            <div
                              className="pb-1"
                              style={{
                                width: "100%",
                                //   borderBottom: '1px solid ' + colors.brown,
                              }}
                            >
                              <span
                                style={{
                                  color: colors.brown,
                                  fontSize: fontSizes.medium,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.bold,
                                  textAlign: "left",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {floor.name ? floor.name + " - " : ""}
                                <span
                                  style={{
                                    color: colors.lightBlue,
                                    fontSize: fontSizes.medium,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.bold,
                                    textAlign: "left",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {floor.price != null
                                    ? floor.price.toLocaleString("fr-FR") +
                                      "€/m²/an - "
                                    : ""}
                                </span>

                                <span
                                  style={{
                                    color: colors.lightBlue,
                                    fontSize: fontSizes.medium,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.bold,
                                    textAlign: "left",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {floor.surface != null
                                    ? floor.surface.toLocaleString("fr-FR") +
                                      "m²"
                                    : null}
                                </span>
                              </span>
                            </div>
                          ))}
                        </div>
                      </span>
                      <span
                        style={{
                          color: colors.blue,
                          fontSize: fontSizes.medium,
                          fontWeight: fontWeights.bold,
                          textAlign: "right",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Broker
                        <br />
                        <span
                          style={{
                            color: colors.brown,
                            fontSize: fontSizes.medium,
                            fontFamily: "Montserrat",
                            fontWeight: fontWeights.bold,
                            textAlign: "left",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {property.brokers}
                        </span>
                      </span>
                    </div>

                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          color: colors.blue,
                          fontSize: fontSizes.medium,
                          fontFamily: "Montserrat",
                          fontWeight: fontWeights.bold,
                          textAlign: "left",
                        }}
                      >
                        Places de parking
                        <br />
                        <span
                          style={{
                            color: colors.brown,
                            fontSize: fontSizes.medium,
                            fontFamily: "Montserrat",
                            fontWeight: fontWeights.bold,
                            textAlign: "left",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {property.parking_spaces != 0
                            ? property.parking_spaces
                            : "N/A"}
                        </span>
                      </span>
                      <span
                        style={{
                          color: colors.blue,
                          fontSize: fontSizes.medium,
                          fontFamily: "Montserrat",
                          fontWeight: fontWeights.bold,
                          textAlign: "right",
                        }}
                      >
                        Loyer parking (HT HC)
                        <br />
                        <span
                          style={{
                            color: colors.brown,
                            fontSize: fontSizes.medium,
                            fontFamily: "Montserrat",
                            fontWeight: fontWeights.bold,
                            textAlign: "left",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {property.parking_price ? (
                            <>
                              <span
                                style={{
                                  color: colors.brown,
                                  fontSize: fontSizes.medium,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.bold,
                                  textAlign: "left",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {property.parking_price + ""}
                              </span>
                              <span
                                style={{
                                  color: colors.lightBlue,
                                  fontSize: fontSizes.small,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.bold,
                                  textAlign: "left",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                €/U/an
                              </span>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </span>
                      </span>
                    </div>
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          color: colors.blue,
                          fontSize: fontSizes.medium,
                          fontFamily: "Montserrat",
                          fontWeight: fontWeights.bold,
                          textAlign: "left",
                        }}
                      >
                        Taxe bureaux
                        <br />
                        <span
                          style={{
                            color: colors.brown,
                            fontSize: fontSizes.medium,
                            fontFamily: "Montserrat",
                            fontWeight: fontWeights.bold,
                            textAlign: "left",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {property.office_taxes ? (
                            <>
                              <span
                                style={{
                                  color: colors.brown,
                                  fontSize: fontSizes.medium,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.bold,
                                  textAlign: "left",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {property.office_taxes + ""}
                              </span>
                              <span
                                style={{
                                  color: colors.lightBlue,
                                  fontSize: fontSizes.small,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.bold,
                                  textAlign: "left",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                €/m²/an
                              </span>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </span>
                      </span>
                      <span
                        style={{
                          color: colors.blue,
                          fontSize: fontSizes.medium,
                          fontFamily: "Montserrat",
                          fontWeight: fontWeights.bold,
                          textAlign: "right",
                        }}
                      >
                        Fiscalité (TF/TEOM)
                        <br />
                        <span
                          style={{
                            color: colors.brown,
                            fontSize: fontSizes.medium,
                            fontFamily: "Montserrat",
                            fontWeight: fontWeights.bold,
                            textAlign: "left",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {property.taxation ? (
                            <>
                              <span
                                style={{
                                  color: colors.brown,
                                  fontSize: fontSizes.medium,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.bold,
                                  textAlign: "left",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {property.taxation + ""}
                              </span>
                              <span
                                style={{
                                  color: colors.lightBlue,
                                  fontSize: fontSizes.small,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.bold,
                                  textAlign: "left",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                €/m²/an
                              </span>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </span>
                      </span>
                    </div>
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          color: colors.blue,
                          fontSize: fontSizes.medium,
                          fontFamily: "Montserrat",
                          fontWeight: fontWeights.bold,
                          textAlign: "left",
                        }}
                      >
                        Loyer RIE (HT HC)
                        <br />
                        <span
                          style={{
                            color: colors.brown,
                            fontSize: fontSizes.medium,
                            fontFamily: "Montserrat",
                            fontWeight: fontWeights.bold,
                            textAlign: "left",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {property.rie_price ? (
                            <>
                              <span
                                style={{
                                  color: colors.brown,
                                  fontSize: fontSizes.medium,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.bold,
                                  textAlign: "left",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {property.rie_price}
                              </span>
                              <span
                                style={{
                                  color: colors.lightBlue,
                                  fontSize: fontSizes.small,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.bold,
                                  textAlign: "left",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                €/m²/an
                              </span>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </span>
                      </span>
                      <span
                        style={{
                          color: colors.blue,
                          fontSize: fontSizes.medium,
                          fontFamily: "Montserrat",
                          fontWeight: fontWeights.bold,
                          textAlign: "right",
                        }}
                      >
                        Charges
                        <br />
                        <span
                          style={{
                            color: colors.brown,
                            fontSize: fontSizes.medium,
                            fontFamily: "Montserrat",
                            fontWeight: fontWeights.bold,
                            textAlign: "left",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {property.charges_price
                            ? property.charges_price + "€"
                            : "N/A"}
                        </span>
                      </span>
                    </div>
                    <div
                      className="p-2 mt-2"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        borderRadius: 10,
                        backgroundColor: colors.white,
                        flexDirection: "column",
                      }}
                    >
                      <span
                        className="mb-1"
                        style={{
                          color: colors.blue,
                          fontSize: fontSizes.medium,
                          fontFamily: "Montserrat",
                          fontWeight: fontWeights.bold,
                          textAlign: "left",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Services dans l'immeuble
                      </span>
                      <div
                        style={{
                          width: "100%",
                          height: 60,
                          overflowY: "scroll",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <span
                          style={{
                            color: colors.blue,
                            fontSize: fontSizes.medium,
                            fontFamily: "Montserrat",
                            fontWeight: fontWeights.medium,
                            textAlign: "left",
                          }}
                        >
                          {property.services.map((service, index) => (
                            <React.Fragment key={index}>
                              {service}
                              <br />
                            </React.Fragment>
                          ))}
                        </span>
                      </div>
                    </div>
                    <div
                      className="p-2 mt-2 mb-2"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        borderRadius: 10,
                        backgroundColor: colors.white,
                        flexDirection: "column",
                      }}
                    >
                      <span
                        className="mb-1"
                        style={{
                          color: colors.blue,
                          fontSize: fontSizes.medium,
                          fontFamily: "Montserrat",
                          fontWeight: fontWeights.bold,
                          textAlign: "left",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Commentaires
                      </span>
                      <div
                        style={{
                          width: "100%",
                          height: 60,
                          overflowY: "scroll",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <span
                          style={{
                            color: colors.blue,
                            fontSize: fontSizes.medium,
                            fontFamily: "Montserrat",
                            fontWeight: fontWeights.medium,
                            textAlign: "left",
                          }}
                        >
                          {property.comments}
                        </span>
                      </div>
                    </div>
                    <div
                      onClick={async () =>
                        await exportPropertySourcePDF(property.id)
                      }
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          color: colors.blue,
                          fontSize: fontSizes.medium,
                          fontFamily: "Montserrat",
                          fontWeight: fontWeights.bold,
                          textAlign: "left",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Source
                        <br />
                        <CloudArrowDownFill
                          size={18}
                          style={{
                            cursor: "pointer",
                          }}
                        ></CloudArrowDownFill>
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div style={{ display: "flex" }}></div>
            </Col>
          </animated.div>
        )}
      </Spring>
    );
  }
}

export default PropertyModal;
