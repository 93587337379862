import axios from "axios";
import { BASE_URL } from "./config";

const api = axios.create({
  baseURL: BASE_URL,
});
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
export const createProject = async (project) => {
  try {
    const response = await api.post("/projects/", project);
    return response.data;
  } catch (error) {
    console.error("Error creating project:", error);
    throw error;
  }
};

export const getProjects = async (skip = 0, limit = 100) => {
  try {
    const response = await api.get("/projects/", { params: { skip, limit } });
    return response.data;
  } catch (error) {
    console.error("Error getting projects:", error);
    throw error;
  }
};

export const getProject = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}`);
    return response.data;
  } catch (error) {
    console.error(`Error getting project with ID ${projectId}:`, error);
    throw error;
  }
};

export const updateProject = async (projectId, project) => {
  try {
    const response = await api.put(`/projects/${projectId}`, project);
    return response.data;
  } catch (error) {
    console.error(`Error updating project with ID ${projectId}:`, error);
    throw error;
  }
};

export const deleteProject = async (projectId) => {
  try {
    const response = await api.delete(`/projects/${projectId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting project with ID ${projectId}:`, error);
    throw error;
  }
};

export const login = async (username, password) => {
  try {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    const response = await api.post("/token", formData);
    if (!response.data.access_token) {
      throw new Error("Invalid credentials");
    }
    return response.data;
  } catch (error) {
    console.error("Error logging in:", error);
    throw error;
  }
};

export const refreshToken = async (token) => {
  try {
    const response = await api.post("/token/refresh", { token });
    if (!response.data.access_token) {
      throw new Error("Invalid token");
    }
    return response.data;
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
};

export const getDocument = async (documentId) => {
  try {
    const response = await api.get(`/documents/${documentId}`);
    return response.data;
  } catch (error) {
    console.error(`Error getting document with ID ${documentId}:`, error);
    throw error;
  }
};

export const createDocument = async (document) => {
  try {
    const response = await api.post("/documents/", document);
    return response.data;
  } catch (error) {
    console.error("Error creating document:", error);
    throw error;
  }
};

export const updateDocument = async (documentId, document) => {
  try {
    const response = await api.put(`/documents/${documentId}`, document);
    return response.data;
  } catch (error) {
    console.error(`Error updating document with ID ${documentId}:`, error);
    throw error;
  }
};

export const deleteDocument = async (documentId) => {
  try {
    const response = await api.delete(`/documents/${documentId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting document with ID ${documentId}:`, error);
    throw error;
  }
};

export const getDocumentsByProject = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/documents`);
    return response.data;
  } catch (error) {
    console.error(
      `Error getting documents for project with ID ${projectId}:`,
      error
    );
    throw error;
  }
};

export const uploadDocument = async (projectId, file, layout_type) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await api.post(
      `/projects/${projectId}/documents/upload/${layout_type}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error uploading document for project with ID ${projectId}:`,
      error
    );
    throw error;
  }
};

export const getDocumentFile = async (documentId) => {
  try {
    const response = await api.get(`/documents/${documentId}/file`, {
      responseType: "blob",
    });
    const blob = new Blob([response.data], {
      type: "application/pdf",
    });
    // Instead of downloading the PDF, open it in a new tab
    const url = window.URL.createObjectURL(blob);
    window.open(url, "_blank");
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error(
      `Error getting file for document with ID ${documentId}:`,
      error
    );
    throw error;
  }
};

export const getPropertiesByProject = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/properties`);
    return response.data;
  } catch (error) {
    console.error(
      `Error getting properties for project with ID ${projectId}:`,
      error
    );
    throw error;
  }
};

export const getProperty = async (propertyId) => {
  try {
    const response = await api.get(`/properties/${propertyId}`);
    return response.data;
  } catch (error) {
    console.error(`Error getting property with ID ${propertyId}:`, error);
    throw error;
  }
};

export const createProperty = async (property) => {
  try {
    const response = await api.post(`/properties/`, property);
    return response.data;
  } catch (error) {
    console.error(`Error creating property:`, error);
    throw error;
  }
};

export const updateProperty = async (propertyId, property) => {
  try {
    const response = await api.put(`/properties/${propertyId}`, property);
    return response.data;
  } catch (error) {
    console.error(`Error updating property with ID ${propertyId}:`, error);
    throw error;
  }
};

export const deleteProperty = async (propertyId) => {
  try {
    const response = await api.delete(`/properties/${propertyId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting property with ID ${propertyId}:`, error);
    throw error;
  }
};

export const exportProjectPPTX = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/export_pptx/`, {
      responseType: "blob",
    });
    // Create a new Blob object using the response data of the file
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    });
    // Create a link element, use it to download the blob, and then remove it
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `project_${projectId}_export.pptx`;
    link.click();
    window.URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error(
      `Error exporting project with ID ${projectId} to PPTX:`,
      error
    );
    throw error;
  }
};

export const exportProjectExcel = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/export_excel/`, {
      responseType: "blob",
    });
    // Create a new Blob object using the response data of the file
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    // Create a link element, use it to download the blob, and then remove it
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `project_${projectId}_export.xlsx`;
    link.click();
    window.URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error(
      `Error exporting project with ID ${projectId} to Excel:`,
      error
    );
    throw error;
  }
};

export const exportPropertySourcePDF = async (propertyId) => {
  try {
    const response = await api.get(`/properties/${propertyId}/source`, {
      responseType: "blob",
    });

    const contentDisposition = response.headers["content-disposition"];
    let filename = "download.pdf"; // Default filename as a fallback

    // Attempt to extract the filename from the Content-Disposition header
    if (contentDisposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, ""); // Remove any surrounding quotes
      }
    }

    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);

    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.setAttribute("download", filename); // Use the extracted filename
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    window.open(url, "_blank");

    setTimeout(() => window.URL.revokeObjectURL(url), 100);
  } catch (error) {
    console.error(
      `Error exporting property with ID ${propertyId} to PDF:`,
      error
    );
    throw error;
  }
};

export const updateFloor = async (floorId, floorData) => {
  try {
    const response = await api.put(`/floors/${floorId}`, floorData);
    return response.data;
  } catch (error) {
    console.error(`Error updating floor with ID ${floorId}:`, error);
    throw error;
  }
};

export const fetchProjectToken = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/token`);
    return response.data.token;
  } catch (error) {
    console.error(
      `Error fetching token for project with ID ${projectId}:`,
      error
    );
    throw error;
  }
};

export const authToProject = async (token) => {
  try {
    const response = await api.post(
      "/auth_to_project",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(`Error authenticating to project with token:`, error);
    throw error;
  }
};

export const generateNewProjectToken = async (projectId) => {
  try {
    const response = await api.post(
      `/projects/${projectId}/generate_new_token/`
    );
    return response.data.token;
  } catch (error) {
    console.error(
      `Error generating new token for project with ID ${projectId}:`,
      error
    );
    throw error;
  }
};

export const fetchProjectHeadquarters = async (projectId) => {
  try {
    const response = await api.get(`/projects/${projectId}/headquarters/`);
    return response.data;
  } catch (error) {
    console.error(
      `Error fetching headquarters for project with ID ${projectId}:`,
      error
    );
    throw error;
  }
};
