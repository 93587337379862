import React from "react";

class PinBlue extends React.Component {
  render() {
    const { color } = this.props;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 27 35"
        style={{ filter: "drop-shadow(0px 0px 0px rgba(0,0,0,0.1))" }}
      >
        <path
          fill={color || "#4D77B5"}
          filter="url(#shadow)"
          d="M13.125 35S26.25 22.562 26.25 13.125a13.125 13.125 0 10-26.25 0C0 22.562 13.125 35 13.125 35zm0-15.313a6.562 6.562 0 110-13.124 6.562 6.562 0 010 13.125z"
        ></path>
        {/* <ellipse
          cx="13.5"
          cy="13.125"
          fill={color || "#4D77B5"}
          rx="8.75"
          ry="10.938"
        ></ellipse> */}
        {/* <path
          fill='#F8F9FC'
          d='M6 11.795h4.17l2.134 2.09 2.136 2.094v4.334h-2.136v-6.428H6v-2.09zM11.898 11.563h2.188V5h-2.188v6.563zm.294-6.234h1.6v5.906h-1.6V5.329z'
        ></path> */}
        {/* <path
          fill="#F8F9FC"
          d="M14.085 13.75h6.563v-2.188h-6.563v2.188zm.33-1.894h5.903v1.602h-5.903v-1.602z"
        ></path> */}
      </svg>
    );
  }
}

export default PinBlue;
