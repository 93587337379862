import React, { Component } from "react";
import { colors } from "../colors";
import { withRouter } from "../withRouter";
import { Scatter } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(ChartDataLabels);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

class ChartView extends Component {
  constructor(props) {
    super(props);
  }
  handlePointClick = (event, array) => {
    if (array.length > 0) {
      if (array[0].name === "Siège") return;
      const index = array[0].index;
      const property = this.props.properties[index];
      this.props.displayPropertyModal(property);

      // Perform your action here
    }
  };
  render() {
    const properties = this.props.properties;

    if (properties.length === 0) {
      return <div> No properties found </div>;
    }

    const datasetData = properties
      .filter((property) => property.office_price) // Ensure only properties with office_price are included
      .map((property) => ({
        x: property.floor_size,
        y:
          property.office_price.reduce((a, b) => a + b, 0) /
          property.office_price.length,
        name: property.name,
      }));

    const datasetLabels = properties
      .filter((property) => property.office_price) // Filter properties with office_price
      .map((property) => property.floor_size);

    const datasetBGColors = properties
      .filter((property) => property.office_price) // Consistent filtering
      .map((property) => (property.selected ? colors.brown : colors.lightBlue));

    if (
      this.props.headquarters.headquarter_price &&
      this.props.headquarters.headquarter_surface
    ) {
      datasetData.push({
        x: this.props.headquarters.headquarter_surface,
        y: this.props.headquarters.headquarter_price,
        name: "Site",
      });
      datasetLabels.push(this.props.headquarters.headquarter_surface);
      datasetBGColors.push(colors.blue);
    }

    const data = {
      labels: datasetLabels,
      datasets: [
        {
          data: datasetData,
          backgroundColor: datasetBGColors,
          pointHoverRadius: 20,
          pointRadius: 15,
          borderWidth: 1,
          borderColor: colors.background,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: "linear",
          title: {
            display: true,
            text: "Surface du plateau (m²)",
          },
          beginAtZero: true,
        },
        y: {
          type: "linear",
          title: {
            display: true,
            text: "Loyer facial chargé (€/m²/an)",
          },
          beginAtZero: true,
        },
      },
      plugins: {
        datalabels: {
          align: "end",
          anchor: "end",
          formatter: function (value, context) {
            return value.name;
          },
          color: colors.blue,
          labels: {
            title: {
              font: {
                family: "Montserrat",
                size: 14,
              },
            },
          },
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            title: function (context) {
              return context[0].raw.name;
            },
            label: function (context) {
              return [
                "Surface : " + context.raw.x + "m²",
                "Loyer : " + context.raw.y + "€/m²/an",
              ];
            },
          },
        },
        legend: {
          labels: {
            usePointStyle: true,
            generateLabels: function (chart) {
              return [
                {
                  text: "Sélectionné",
                  fillStyle: colors.brown,
                  borderWidth: 0,
                },
                {
                  text: "Non sélectionné",
                  fillStyle: colors.lightBlue,
                  borderColor: colors.lightBlue,
                },
              ];
            },
          },
        },
      },

      onClick: this.handlePointClick,
    };

    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          flex: 1,
          maxHeight: "100%",
        }}
      >
        <Scatter data={data} options={options} />
      </div>
    );
  }
}

export default withRouter(ChartView);
