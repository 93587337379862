import React, { Component } from 'react';
import { colors } from '../colors';
import { fontSizes } from '../fontSizes';

class TextInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <input
        onBlur={this.props.onBlur}
        type='text'
        style={{
          width: '100%',
          backgroundColor: colors.background,
          borderWidth: 0,
          borderBottomWidth: 1,
          borderBottomColor: colors.lightBlue,
          color: colors.blue,
          fontFamily: 'Montserrat',
          fontSize: fontSizes.medium,
        }}
        {...this.props}
      />
    );
  }
}

export default TextInput;
