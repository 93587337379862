import React, { Component } from "react";
import { colors } from "../colors";
import { fontSizes } from "../fontSizes";
import { fontWeights } from "../fontWeights";
import { Spring, animated } from "@react-spring/web";
import { withRouter } from "../withRouter";
import { Row, Col } from "react-bootstrap";
import PinBlue from "../assets/PinBlueSVG";
import AutoSizer from "react-virtualized-auto-sizer";
import { EyeFill, CheckLg } from "react-bootstrap-icons";
import { APIProvider, Map, AdvancedMarker } from "@vis.gl/react-google-maps";
import _ from "lodash";
import { BASE_URL } from "../config";
import PinBlueHome from "../assets/PinBlueHomeSVG";

class MapView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      centerLat: 0,
      centerLng: 0,
      zoom: 12,
      propertiesFiltered: [],
      propertyRefs: {}, // Object to hold refs
    };
    this.mapRef = React.createRef();
  }
  calculateZoom(properties) {
    const WORLD_DIM = { height: 256, width: 256 };
    const ZOOM_MAX = 21;

    const latitudes = properties.map((property) => property.lat);
    const longitudes = properties.map((property) => property.long);

    const minLat = Math.min(...latitudes);
    const maxLat = Math.max(...latitudes);
    const minLng = Math.min(...longitudes);
    const maxLng = Math.max(...longitudes);

    const latFraction = (latRad(maxLat) - latRad(minLat)) / Math.PI;

    const lngDiff = maxLng - minLng;
    const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

    const latZoom = zoom(WORLD_DIM.height, latFraction);
    const lngZoom = zoom(WORLD_DIM.width, lngFraction);

    const zoomLevel = Math.min(latZoom, lngZoom, ZOOM_MAX);

    return Math.floor(zoomLevel);

    function latRad(lat) {
      const sin = Math.sin((lat * Math.PI) / 180);
      const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }
  }

  async selectProperty(property) {
    try {
      const propertyUpdated = await this.props.selectProperty(
        property.id,
        !property.selected
      );
      // if (this.state.selectedProperty?.id == property.id) {
      //   this.setState({
      //     selectedProperty: propertyUpdated,
      //     centerLat: propertyUpdated.lat,
      //     centerLng: propertyUpdated.long,
      //     zoom: 15,
      //   });
      // }
    } catch {}
  }

  filterProperties(properties, bounds) {
    const filtered = properties.filter((property) => {
      return (
        property.lat >= bounds.south &&
        property.lat <= bounds.north &&
        property.long >= bounds.west &&
        property.long <= bounds.east
      );
    });
    this.setState({ propertiesFiltered: filtered });
  }

  async componentDidMount() {
    const properties = this.props.properties;

    if (properties.length == 0) {
      return;
    }

    const centerLat =
      properties.reduce((total, property) => total + property.lat, 0) /
      properties.length;
    const centerLng =
      properties.reduce((total, property) => total + property.long, 0) /
      properties.length;

    this.setState({ centerLat, centerLng });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.properties.length == 0 && this.props.properties.length > 0) {
      const properties = this.props.properties;
      const centerLat =
        properties.reduce((total, property) => total + property.lat, 0) /
        properties.length;
      const centerLng =
        properties.reduce((total, property) => total + property.long, 0) /
        properties.length;

      this.setState({ centerLat, centerLng });
    }
    if (!_.isEqual(prevProps.properties, this.props.properties)) {
      const properties = this.props.properties;

      if (properties.length == 0) {
        this.setState({ propertiesFiltered: [] });
      }
      if (this.state.bounds)
        this.filterProperties(properties, this.state.bounds);
    }
  }

  render() {
    const properties = this.props.properties;

    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          flex: 1,
          maxHeight: "100%",
        }}
      >
        <Row
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            flex: 1,
          }}
        >
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            md={6}
            xs={12}
          >
            <APIProvider apiKey="AIzaSyDGdKl24Fpq8cml_XXlQUXAhmNMtnL3XGI">
              <Map
                onBoundsChanged={(e) => {
                  this.setState({ bounds: e.detail.bounds });
                  this.filterProperties(properties, e.detail.bounds);
                }}
                style={{ borderRadius: 10 }}
                disableDefaultUI={true}
                zoom={this.state.zoom}
                center={{
                  lat: this.state.centerLat,
                  lng: this.state.centerLng,
                }}
                mapId={"16c4311bb211988c"}
              >
                {this.props.headquarters?.headquarter_lat &&
                  this.props.headquarters?.headquarter_long && (
                    <AdvancedMarker
                      position={{
                        lat: this.props.headquarters?.headquarter_lat,
                        lng: this.props.headquarters?.headquarter_long,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            height: 30,
                            width: 30,
                          }}
                        >
                          <PinBlueHome color={colors.blue}></PinBlueHome>
                        </div>

                        <span
                          className="mt-2"
                          style={{
                            color: colors.blue,
                            fontSize: fontSizes.small,
                            fontFamily: "Montserrat",
                            fontWeight: fontWeights.bold,
                          }}
                        >
                          {"Site"}
                        </span>
                      </div>
                    </AdvancedMarker>
                  )}

                {properties.map((property, index) => (
                  <AdvancedMarker
                    key={index}
                    onClick={() => {
                      const propertyRef = this.state.propertyRefs[property.id];
                      if (propertyRef && propertyRef.current) {
                        propertyRef.current.scrollIntoView({
                          behavior: "smooth",
                          block: "nearest",
                        });
                      }
                      this.setState({
                        centerLat: property.lat,
                        centerLng: property.long,
                        zoom: 15,
                        selectedProperty: property,
                      });
                    }}
                    position={{ lat: property.lat, lng: property.long }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spring
                        height={
                          this.state.selectedProperty?.id == property.id
                            ? 30
                            : 20
                        }
                        width={
                          this.state.selectedProperty?.id == property.id
                            ? 30
                            : 20
                        }
                      >
                        {(props) => (
                          <animated.div
                            style={{
                              ...props,
                            }}
                          >
                            <PinBlue
                              color={
                                property.selected
                                  ? colors.brown
                                  : colors.lightBlue
                              }
                            ></PinBlue>
                          </animated.div>
                        )}
                      </Spring>
                      <span
                        className="mt-2"
                        style={{
                          color: colors.blue,
                          fontSize: fontSizes.small,
                          fontFamily: "Montserrat",
                          fontWeight: fontWeights.bold,
                        }}
                      >
                        {property.name}
                      </span>
                    </div>
                  </AdvancedMarker>
                ))}
              </Map>
            </APIProvider>
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            md={6}
            xs={12}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <AutoSizer>
                {({ height, width }) => (
                  <div
                    className="p-1"
                    style={{
                      width: width,
                      height: height,
                      overflowY: "scroll",
                      flexDirection: "column",
                    }}
                  >
                    {this.state.propertiesFiltered.map((property, index) => {
                      if (!this.state.propertyRefs[property.id]) {
                        this.setState((prevState) => ({
                          propertyRefs: {
                            ...prevState.propertyRefs,
                            [property.id]: React.createRef(),
                          },
                        }));
                      }
                      return (
                        <Spring
                          from={{ opacity: 0 }}
                          key={property.id}
                          to={{ opacity: 1 }}
                          delay={index * 50}
                        >
                          {(props) => (
                            <animated.div
                              className="mb-2"
                              style={{
                                width: "100%",
                                ...props,
                                display: "flex",
                                flexDirection: "row",
                              }}
                              key={property.id}
                              ref={this.state.propertyRefs[property.id]}
                            >
                              <div
                                onClick={() => {
                                  const propertyRef =
                                    this.state.propertyRefs[property.id];
                                  if (propertyRef && propertyRef.current) {
                                    propertyRef.current.scrollIntoView({
                                      behavior: "smooth",
                                      block: "nearest",
                                    });
                                  }
                                  this.setState({
                                    centerLat: property.lat,
                                    centerLng: property.long,
                                    zoom: 15,
                                    selectedProperty: property,
                                  });
                                }}
                                style={{
                                  cursor: "pointer",
                                  width: "90%",
                                  display: "flex",
                                  flexDirection: "column",
                                  borderRadius: 10,
                                  overflow: "hidden",
                                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10)",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    height: 100,
                                  }}
                                >
                                  <div
                                    className="p-2"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "flex-start",
                                      alignItems: "flex-start",
                                      flex: 1,

                                      backgroundColor: colors.lightBlue,
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: colors.white,
                                        fontSize: fontSizes.medium,
                                        fontFamily: "Montserrat",
                                        fontWeight: fontWeights.bold,
                                      }}
                                    >
                                      {property.name}
                                    </span>
                                    <span
                                      style={{
                                        color: colors.white,
                                        fontSize: fontSizes.small,
                                        fontFamily: "Montserrat",
                                        fontWeight: fontWeights.medium,
                                        textAlign: "left",
                                      }}
                                    >
                                      {property.address}, {property.postal_code}
                                      , {property.city}
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      height: 100,
                                      backgroundColor: colors.lightBlue,
                                    }}
                                  >
                                    {property.image_path !== null && (
                                      <img
                                        style={{
                                          objectFit: "cover",
                                          width: "100%",
                                          height: "100%",
                                        }}
                                        src={
                                          BASE_URL +
                                          "properties/" +
                                          property.id +
                                          "/image"
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                                <Spring
                                  height={
                                    this.state.selectedProperty?.id ==
                                    property.id
                                      ? 250
                                      : 50
                                  }
                                >
                                  {(props) => (
                                    <animated.div
                                      className="p-2"
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        ...props,
                                      }}
                                    >
                                      {this.state.selectedProperty?.id ==
                                        property.id && (
                                        <>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <span
                                              style={{
                                                color: colors.blue,
                                                fontSize: fontSizes.small,
                                                fontFamily: "Montserrat",
                                                fontWeight: fontWeights.bold,
                                                textAlign: "left",
                                              }}
                                            >
                                              Disponibilité
                                              <br />
                                              <span
                                                style={{
                                                  color: colors.brown,
                                                  fontSize: fontSizes.small,
                                                  fontFamily: "Montserrat",
                                                  fontWeight: fontWeights.bold,
                                                  textAlign: "left",
                                                  textOverflow: "ellipsis",
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {property.availability}
                                              </span>
                                            </span>
                                            <span
                                              style={{
                                                color: colors.blue,
                                                fontSize: fontSizes.small,
                                                fontFamily: "Montserrat",
                                                fontWeight: fontWeights.bold,
                                                textAlign: "right",
                                              }}
                                            >
                                              Etat
                                              <br />
                                              <span
                                                style={{
                                                  color: colors.brown,
                                                  fontSize: fontSizes.small,
                                                  fontFamily: "Montserrat",
                                                  fontWeight: fontWeights.bold,
                                                  textAlign: "left",
                                                  textOverflow: "ellipsis",
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {property.state}
                                              </span>
                                            </span>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <span
                                              style={{
                                                color: colors.blue,
                                                fontSize: fontSizes.small,
                                                fontFamily: "Montserrat",
                                                fontWeight: fontWeights.bold,
                                                textAlign: "left",
                                              }}
                                            >
                                              Accès transports
                                              <br />
                                              {property.public_transports
                                                .sort(
                                                  (a, b) =>
                                                    a.distance_to_property_minutes -
                                                    b.distance_to_property_minutes
                                                )
                                                .slice(0, 3)
                                                .map((transport) => (
                                                  <span
                                                    style={{
                                                      color: colors.brown,
                                                      fontSize: fontSizes.small,
                                                      fontFamily: "Montserrat",
                                                      fontWeight:
                                                        fontWeights.bold,
                                                      textAlign: "left",
                                                      textOverflow: "ellipsis",
                                                      overflow: "hidden",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                  >
                                                    {transport.name} (
                                                    <span
                                                      style={{
                                                        color: colors.lightBlue,
                                                        fontSize:
                                                          fontSizes.small,
                                                        fontFamily:
                                                          "Montserrat",
                                                        fontWeight:
                                                          fontWeights.bold,
                                                        textAlign: "left",
                                                        textOverflow:
                                                          "ellipsis",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                      }}
                                                    >
                                                      {
                                                        transport.distance_to_property_minutes
                                                      }{" "}
                                                      min
                                                    </span>
                                                    ) <br />
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      {transport.lines.map(
                                                        (line) => (
                                                          <div
                                                            className="me-1"
                                                            style={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "center",
                                                              alignItems:
                                                                "center",
                                                              borderRadius: 3,
                                                              width: 20,
                                                              color:
                                                                "#" +
                                                                line.text_color,
                                                              backgroundColor:
                                                                "#" +
                                                                line.color,
                                                            }}
                                                          >
                                                            {line.name}
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  </span>
                                                ))}
                                            </span>
                                            <span
                                              style={{
                                                color: colors.blue,
                                                fontSize: fontSizes.small,
                                                fontFamily: "Montserrat",
                                                fontWeight: fontWeights.bold,
                                                textAlign: "right",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              Divibilité
                                              <br />
                                              <span
                                                style={{
                                                  color: colors.brown,
                                                  fontSize: fontSizes.small,
                                                  fontFamily: "Montserrat",
                                                  fontWeight: fontWeights.bold,
                                                  textAlign: "left",
                                                  textOverflow: "ellipsis",
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {property.division
                                                  ? "oui"
                                                  : "non"}
                                              </span>
                                            </span>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <span
                                              style={{
                                                color: colors.blue,
                                                fontSize: fontSizes.small,
                                                fontFamily: "Montserrat",
                                                fontWeight: fontWeights.bold,
                                                textAlign: "left",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              Etages
                                              <br />
                                              <span
                                                style={{
                                                  color: colors.brown,
                                                  fontSize: fontSizes.small,
                                                  fontFamily: "Montserrat",
                                                  fontWeight: fontWeights.bold,
                                                  textAlign: "left",
                                                  // textOverflow: "ellipsis",
                                                  // overflow: "hidden",
                                                  // whiteSpace: "nowrap",
                                                }}
                                              >
                                                {property.concerned_floors.join(
                                                  " - "
                                                )}
                                              </span>
                                            </span>
                                            <span
                                              style={{
                                                color: colors.blue,
                                                fontSize: fontSizes.small,
                                                fontFamily: "Montserrat",
                                                fontWeight: fontWeights.bold,
                                                textAlign: "right",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              Broker
                                              <br />
                                              <span
                                                style={{
                                                  color: colors.brown,
                                                  fontSize: fontSizes.small,
                                                  fontFamily: "Montserrat",
                                                  fontWeight: fontWeights.bold,
                                                  textAlign: "left",
                                                  textOverflow: "ellipsis",
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {property.brokers}
                                              </span>
                                            </span>
                                          </div>
                                        </>
                                      )}

                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          width: "100%",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: colors.blue,
                                            fontSize: fontSizes.large,
                                            fontFamily: "Montserrat",
                                            fontWeight: fontWeights.bold,
                                          }}
                                        >
                                          {property.floor_size
                                            ? property.floor_size.toLocaleString(
                                                "fr-FR"
                                              )
                                            : "- "}
                                          m²
                                        </span>
                                        {property.office_price?.length > 0 && (
                                          <span
                                            style={{
                                              color: colors.brown,
                                              fontSize: fontSizes.large,
                                              fontFamily: "Montserrat",
                                              fontWeight: fontWeights.bold,
                                            }}
                                          >
                                            {property.office_price
                                              .map((price) =>
                                                price.toLocaleString("fr-FR")
                                              )
                                              .join(" - ")}
                                            €
                                            <span
                                              style={{
                                                color: colors.brown,
                                                fontSize: fontSizes.medium,
                                                fontFamily: "Montserrat",
                                                fontWeight: fontWeights.bold,
                                              }}
                                            >
                                              /m²/an
                                            </span>
                                          </span>
                                        )}
                                      </div>
                                    </animated.div>
                                  )}
                                </Spring>
                              </div>
                              <div
                                style={{
                                  width: "10%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Spring
                                  backgroundColor={
                                    property.selected
                                      ? colors.lightBlue
                                      : colors.lightBlue
                                  }
                                >
                                  {(props) => (
                                    <div
                                      className="m-1"
                                      onClick={() =>
                                        this.props.displayPropertyModal(
                                          property
                                        )
                                      }
                                      style={{
                                        cursor: "pointer",
                                        width: "70%",
                                        maxWidth: 40,
                                        aspectRatio: "1/1",
                                        borderRadius: "50%",
                                        backgroundColor: colors.background,
                                        display: "flex",
                                        justifyContent: "center",
                                        position: "relative",
                                        alignItems: "center",
                                        boxShadow:
                                          "0px 0px 6px rgba(0, 0, 0, 0.10)",
                                      }}
                                    >
                                      <animated.div
                                        style={{
                                          display: "flex",
                                          width: "70%",
                                          height: "70%",
                                          borderRadius: "50%",

                                          justifyContent: "center",
                                          alignItems: "center",
                                          ...props,
                                        }}
                                      ></animated.div>
                                      <EyeFill
                                        style={{ position: "absolute" }}
                                        size={"50%"}
                                        color={colors.background}
                                      ></EyeFill>
                                    </div>
                                  )}
                                </Spring>
                                {!this.props.userMode && (
                                  <Spring
                                    backgroundColor={
                                      property.selected
                                        ? colors.brown
                                        : colors.white
                                    }
                                  >
                                    {(props) => (
                                      <div
                                        className="m-1"
                                        onClick={() =>
                                          this.selectProperty(property)
                                        }
                                        style={{
                                          cursor: "pointer",
                                          width: "70%",
                                          aspectRatio: "1/1",
                                          borderRadius: "50%",
                                          maxWidth: 40,

                                          backgroundColor: colors.background,
                                          display: "flex",
                                          justifyContent: "center",
                                          position: "relative",
                                          alignItems: "center",
                                          boxShadow:
                                            "0px 0px 6px rgba(0, 0, 0, 0.10)",
                                        }}
                                      >
                                        <animated.div
                                          style={{
                                            display: "flex",
                                            width: "70%",
                                            height: "70%",
                                            borderRadius: "50%",

                                            justifyContent: "center",
                                            alignItems: "center",
                                            ...props,
                                          }}
                                        ></animated.div>
                                        <CheckLg
                                          style={{ position: "absolute" }}
                                          size={"50%"}
                                          color={colors.background}
                                        ></CheckLg>
                                      </div>
                                    )}
                                  </Spring>
                                )}
                              </div>
                            </animated.div>
                          )}
                        </Spring>
                      );
                    })}
                  </div>
                )}
              </AutoSizer>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(MapView);
