import React, { Component } from "react";
import { colors } from "../colors";
import { fontSizes } from "../fontSizes";
import { fontWeights } from "../fontWeights";
import { Spring, animated } from "@react-spring/web";
import { withRouter } from "../withRouter";
import { Spinner } from "react-bootstrap";
import AutoSizer from "react-virtualized-auto-sizer";
import { EyeFill, CheckLg } from "react-bootstrap-icons";
import _ from "lodash";

class TableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      centerLat: 0,
      centerLng: 0,
      zoom: 12,
      edit: false,
      propertiesFiltered: [],
    };
    this.mapRef = React.createRef();
  }
  handlePropertyChange = (index, field, value, updateApi = false) => {
    const updatedProperties = [...this.state.propertiesFiltered];
    updatedProperties[index] = { ...updatedProperties[index], [field]: value };
    this.setState({ propertiesFiltered: updatedProperties });
    // Assuming you have a method to update the properties in the parent component
    // this.props.updateProperties(updatedProperties);
    if (updateApi) this.props.updateProperties(updatedProperties[index]);
  };

  handleFloorChange = (
    propertyIndex,
    floorIndex,
    field,
    value,
    updateApi = false
  ) => {
    const updatedProperties = [...this.state.propertiesFiltered];
    const updatedFloors = [...updatedProperties[propertyIndex].floors];
    updatedFloors[floorIndex] = {
      ...updatedFloors[floorIndex],
      [field]: value,
    };
    updatedProperties[propertyIndex].floors = updatedFloors;
    this.setState({ propertiesFiltered: updatedProperties });
    // Assuming you have a method to update the properties in the parent component
    // this.props.updateProperties(updatedProperties);
    if (updateApi)
      this.props.updatePropertyFloor(
        updatedProperties[propertyIndex].id,
        updatedFloors[floorIndex].id,
        updatedFloors[floorIndex]
      );
  };
  async componentDidMount() {
    this.setState({ propertiesFiltered: this.props.properties });
  }

  async componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.properties, this.props.properties)) {
      const properties = this.props.properties;

      this.setState({ propertiesFiltered: properties });
    }
  }

  render() {
    const properties = this.state.propertiesFiltered;

    if (properties.length == 0) {
      return <div> No properties found </div>;
    }

    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          flex: 1,
          maxHeight: "100%",
        }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <div
              style={{
                height: height,
                width: width,
                overflowY: "scroll",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: height,
                  width: 2000,
                  overflowY: "scroll",
                }}
              >
                <div
                  className="mb-2 px-2"
                  style={{
                    display: "flex",
                    height: 80,
                    borderRadius: 10,
                    width: "95%",
                    backgroundColor: colors.blue,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      height: 80,
                      flex: 2,
                      borderRadius: 10,
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        color: colors.white,
                        fontSize: fontSizes.small,
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.medium,
                      }}
                    >
                      Nom/Adresse
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: 80,
                      flex: 1,
                      borderRadius: 10,
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        color: colors.white,
                        fontSize: fontSizes.small,
                        fontFamily: "Montserrat",
                        textAlign: "left",
                        fontWeight: fontWeights.medium,
                      }}
                    >
                      Loyer facial/Surface
                    </span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      height: 80,
                      flex: 1,
                      borderRadius: 10,
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        color: colors.white,
                        fontSize: fontSizes.small,
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.medium,
                        textAlign: "left",
                      }}
                    >
                      Dispo. / Etat / Divibilité
                    </span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      height: 80,
                      flex: 2,
                      borderRadius: 10,
                      justifyContent: "flex-start ",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        color: colors.white,
                        fontSize: fontSizes.small,
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.medium,
                        textAlign: "left",
                      }}
                    >
                      Etages
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: 80,
                      flex: 1,
                      borderRadius: 10,
                      justifyContent: "flex-start ",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        color: colors.white,
                        fontSize: fontSizes.small,
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.medium,
                        textAlign: "left",
                      }}
                    >
                      Charges / Taxes bureaux / Fiscalité
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: 80,
                      flex: 1,
                      borderRadius: 10,
                      justifyContent: "flex-start ",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        color: colors.white,
                        fontSize: fontSizes.small,
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.medium,
                        textAlign: "left",
                      }}
                    >
                      Places / loyer parking
                    </span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      height: 80,
                      flex: 2,
                      borderRadius: 10,
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        color: colors.white,
                        fontSize: fontSizes.small,
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.medium,
                        textAlign: "left",
                      }}
                    >
                      Transports
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: 80,
                      flex: 1,
                      borderRadius: 10,
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        color: colors.white,
                        fontSize: fontSizes.small,
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.medium,
                        textAlign: "left",
                      }}
                    >
                      Brokers
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: 80,
                      flex: 3,
                      borderRadius: 10,
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        color: colors.white,
                        fontSize: fontSizes.small,
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.medium,
                        textAlign: "left",
                      }}
                    >
                      Services
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: 80,
                      flex: 3,
                      borderRadius: 10,
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        color: colors.white,
                        fontSize: fontSizes.small,
                        fontFamily: "Montserrat",
                        textAlign: "left",
                        fontWeight: fontWeights.medium,
                      }}
                    >
                      Commentaires
                    </span>
                  </div>
                </div>
                {this.state.loading ? (
                  <div
                    style={{
                      display: "flex",

                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Spinner></Spinner>
                  </div>
                ) : (
                  properties.map((property, index) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        maxHeight: 200,
                        height: 200,
                        minHeight: 200,
                      }}
                    >
                      <div
                        className="mb-2 px-2 py-2"
                        style={{
                          display: "flex",

                          borderRadius: 10,
                          width: "95%",
                          backgroundColor: colors.lightBlue,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",

                            flex: 2,
                            borderRadius: 10,
                            justifyContent: "center",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              borderRadius: 10,
                              justifyContent: "flex-start",
                              width: "100%",
                              paddingLeft: 5,
                              paddingRight: 5,
                            }}
                          >
                            {this.props.edit ? (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: this.props.edit
                                    ? "column"
                                    : "row",
                                  borderRadius: 10,
                                  justifyContent: "center",
                                }}
                              >
                                <span
                                  style={{
                                    color: colors.white,
                                    fontSize: 8,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.medium,
                                  }}
                                >
                                  Nom
                                </span>
                                <input
                                  className="mb-2"
                                  type="text"
                                  value={property.name}
                                  onBlur={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "name",
                                      e.target.value,
                                      true
                                    )
                                  }
                                  onChange={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "name",
                                      e.target.value
                                    )
                                  }
                                  style={{
                                    width: "100%",
                                    color: colors.white,
                                    backgroundColor: colors.lightBlue,
                                    border: "none",
                                    borderBottom: "0.2px solid",
                                    borderColor: "rgba(255,255,255, 0.5)",
                                    fontSize: fontSizes.small,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.bold,
                                  }}
                                />
                              </div>
                            ) : (
                              <span
                                style={{
                                  color: colors.white,
                                  fontSize: fontSizes.small,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.bold,
                                  textAlign: "left",
                                }}
                              >
                                {property.name}
                              </span>
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: this.props.edit ? "column" : "row",
                              borderRadius: 10,
                              paddingLeft: 5,
                              paddingRight: 5,
                              width: "100%",

                              justifyContent: "flex-start",
                            }}
                          >
                            {this.props.edit ? (
                              <>
                                <span
                                  style={{
                                    color: colors.white,
                                    fontSize: 8,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.medium,
                                  }}
                                >
                                  Adresse
                                </span>
                                <input
                                  className="mb-2"
                                  type="text"
                                  value={property.address}
                                  onBlur={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "address",
                                      e.target.value,
                                      true
                                    )
                                  }
                                  onChange={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "address",
                                      e.target.value
                                    )
                                  }
                                  style={{
                                    color: colors.white,
                                    backgroundColor: colors.lightBlue,
                                    border: "none",
                                    border: "none",
                                    borderBottom: "0.2px solid",
                                    borderColor: "rgba(255,255,255,0.5)",
                                    fontSize: fontSizes.small,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.medium,
                                  }}
                                />
                                <span
                                  style={{
                                    color: colors.white,
                                    fontSize: 8,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.medium,
                                  }}
                                >
                                  Code postal
                                </span>
                                <input
                                  className="mb-2"
                                  type="text"
                                  value={property.postal_code}
                                  onBlur={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "postal_code",
                                      e.target.value
                                    )
                                  }
                                  onChange={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "postal_code",
                                      e.target.value
                                    )
                                  }
                                  style={{
                                    color: colors.white,
                                    backgroundColor: colors.lightBlue,
                                    border: "none",
                                    fontSize: fontSizes.small,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.medium,
                                    border: "none",
                                    borderBottom: "0.2px solid",
                                    borderColor: "rgba(255,255,255,0.5)",
                                  }}
                                />
                                <span
                                  style={{
                                    color: colors.white,
                                    fontSize: 8,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.medium,
                                  }}
                                >
                                  Ville
                                </span>
                                <input
                                  type="text"
                                  value={property.city}
                                  onBlur={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "city",
                                      e.target.value,
                                      true
                                    )
                                  }
                                  onChange={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "city",
                                      e.target.value
                                    )
                                  }
                                  style={{
                                    color: colors.white,
                                    backgroundColor: colors.lightBlue,
                                    border: "none",
                                    fontSize: fontSizes.small,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.medium,
                                    border: "none",
                                    borderBottom: "0.2px solid",
                                    borderColor: "rgba(255,255,255,0.5)",
                                  }}
                                />
                              </>
                            ) : (
                              <span
                                style={{
                                  color: colors.white,
                                  fontSize: fontSizes.small,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.medium,
                                  textAlign: "left",
                                }}
                              >
                                {property.address}
                                <br />
                                {property.postal_code}, {property.city}
                              </span>
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            borderRadius: 10,
                            justifyContent: "center",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              borderRadius: 10,
                              width: "100%",

                              justifyContent: "flex-start",
                            }}
                          >
                            {this.props.edit ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                  paddingLeft: 5,
                                  paddingRight: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: colors.white,
                                    fontSize: 8,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.medium,
                                  }}
                                >
                                  Loyer facial
                                </span>
                                <input
                                  type="text"
                                  className="mb-2"
                                  value={property.office_price?.join(",")}
                                  onBlur={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "office_price",
                                      e.target.value
                                        .split(",")
                                        .map((price) => price.trim())
                                        .filter((price) => price !== "")
                                        .map((price) => parseFloat(price)),
                                      true
                                    )
                                  }
                                  onChange={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "office_price",
                                      e.target.value
                                        .split(",")
                                        .map((price) => price.trim())
                                    )
                                  }
                                  style={{
                                    width: "100%",

                                    color: colors.blue,
                                    backgroundColor: colors.lightBlue,
                                    border: "none",
                                    fontSize: fontSizes.medium,
                                    fontFamily: "Montserrat",
                                    border: "none",
                                    borderBottom: "0.2px solid",
                                    borderColor: "rgba(255,255,255,0.5)",
                                    fontWeight: fontWeights.bold,
                                  }}
                                />
                              </div>
                            ) : (
                              <span
                                style={{
                                  color: colors.blue,
                                  fontSize: fontSizes.medium,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.bold,
                                  textAlign: "left",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {property.office_price?.length > 0
                                  ? property.office_price
                                      .map((price) =>
                                        price.toLocaleString("fr-FR")
                                      )
                                      .join(" - ") + "€"
                                  : ""}
                              </span>
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              borderRadius: 10,
                              justifyContent: "flex-start",
                            }}
                          >
                            {this.props.edit ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  paddingLeft: 5,
                                  paddingRight: 5,
                                  width: "100%",
                                }}
                              >
                                <span
                                  style={{
                                    color: colors.white,
                                    fontSize: 8,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.medium,
                                  }}
                                >
                                  Surface
                                </span>
                                <input
                                  type="number"
                                  className="mb-2"
                                  value={property.floor_size}
                                  onBlur={(e) => {
                                    if (e.target.value.trim() !== "") {
                                      this.handlePropertyChange(
                                        index,
                                        "floor_size",
                                        e.target.value,
                                        true
                                      );
                                    }
                                  }}
                                  onChange={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "floor_size",
                                      e.target.value
                                    )
                                  }
                                  style={{
                                    color: colors.white,
                                    backgroundColor: colors.lightBlue,
                                    width: "100%",
                                    border: "none",
                                    fontSize: fontSizes.medium,
                                    border: "none",
                                    borderBottom: "0.2px solid",
                                    borderColor: "rgba(255,255,255,0.5)",
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.bold,
                                  }}
                                />
                              </div>
                            ) : (
                              <span
                                style={{
                                  color: colors.white,
                                  fontSize: fontSizes.medium,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.bold,
                                }}
                              >
                                {property.floor_size
                                  ? property.floor_size.toLocaleString("fr-FR")
                                  : "- "}
                                <span
                                  style={{
                                    color: colors.white,
                                    fontSize: fontSizes.small,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.bold,
                                  }}
                                >
                                  m²
                                </span>
                              </span>
                            )}
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            borderRadius: 10,
                            justifyContent: "center",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              borderRadius: 10,
                              justifyContent: "flex-start",
                              width: "100%",
                            }}
                          >
                            {this.props.edit ? (
                              <div
                                style={{
                                  paddingLeft: 5,
                                  paddingRight: 5,
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <span
                                  style={{
                                    color: colors.white,
                                    fontSize: 8,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.medium,
                                  }}
                                >
                                  Dispo.
                                </span>
                                <input
                                  className="mb-2"
                                  type="text"
                                  value={property.availability}
                                  onChange={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "availability",
                                      e.target.value
                                    )
                                  }
                                  onBlur={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "availability",
                                      e.target.value,
                                      true
                                    )
                                  }
                                  style={{
                                    color: colors.white,
                                    backgroundColor: colors.lightBlue,
                                    border: "none",
                                    borderBottom: "0.2px solid",
                                    borderColor: "rgba(255,255,255,0.5)",
                                    fontSize: fontSizes.small,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.medium,
                                    width: "100%",
                                  }}
                                />
                              </div>
                            ) : (
                              <span
                                style={{
                                  color: colors.white,
                                  fontSize: fontSizes.small,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.medium,
                                  textAlign: "left",
                                }}
                              >
                                {property.availability}
                              </span>
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              borderRadius: 10,
                              justifyContent: "flex-start",
                              width: "100%",
                            }}
                          >
                            {this.props.edit ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                  paddingLeft: 5,
                                  paddingRight: 5,
                                }}
                              >
                                <span
                                  style={{
                                    color: colors.white,
                                    fontSize: 8,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.medium,
                                  }}
                                >
                                  Etat
                                </span>
                                <select
                                  className="mb-2"
                                  value={property.state}
                                  onBlur={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "state",
                                      e.target.value,
                                      true
                                    )
                                  }
                                  onChange={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "state",
                                      e.target.value
                                    )
                                  }
                                  style={{
                                    color: colors.white,
                                    backgroundColor: colors.lightBlue,
                                    border: "none",
                                    border: "none",
                                    borderBottom: "0.2px solid",
                                    borderColor: "rgba(255,255,255,0.5)",
                                    fontSize: fontSizes.small,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.medium,
                                    width: "100%",
                                  }}
                                >
                                  <option value="Neuf">Neuf</option>
                                  <option value="Rénové">Rénové</option>
                                  <option value="Restructuré">
                                    Restructuré
                                  </option>
                                  <option value="Etat d'usage">
                                    Etat d'usage
                                  </option>
                                  <option value="Non spécifié">
                                    Non spécifié
                                  </option>
                                </select>
                              </div>
                            ) : (
                              <span
                                style={{
                                  color: colors.white,
                                  fontSize: fontSizes.small,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.medium,
                                }}
                              >
                                {property.state}
                              </span>
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              borderRadius: 10,
                              justifyContent: "flex-start",
                              width: "100%",
                            }}
                          >
                            {this.props.edit ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <span
                                  style={{
                                    color: colors.white,
                                    fontSize: 8,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.medium,
                                  }}
                                >
                                  Divisibilité
                                </span>
                                <div
                                  style={{
                                    width: "100%",

                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    id={`division-${index}`}
                                    checked={property.division}
                                    onBlur={(e) =>
                                      this.handlePropertyChange(
                                        index,
                                        "division",
                                        e.target.checked,
                                        true
                                      )
                                    }
                                    onChange={(e) =>
                                      this.handlePropertyChange(
                                        index,
                                        "division",
                                        e.target.checked
                                      )
                                    }
                                    style={{
                                      marginRight: "5px",
                                      border: "none",
                                      borderBottom: "0.2px solid",
                                      borderColor: "rgba(255,255,255,0.5)",
                                    }}
                                  />
                                  <span
                                    style={{
                                      color: colors.white,
                                      fontSize: fontSizes.small,
                                      fontFamily: "Montserrat",
                                      textAlign: "left",
                                      fontWeight: fontWeights.medium,
                                    }}
                                  >
                                    {property.division ? "Oui" : "Non"}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <span
                                style={{
                                  color: colors.white,
                                  fontSize: fontSizes.small,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.medium,
                                  textAlign: "left",
                                }}
                              >
                                {property.division
                                  ? "Divisible"
                                  : "Non-divisible"}
                              </span>
                            )}
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flex: 2,
                            justifyContent: "center",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            overflowY: "scroll",
                          }}
                        >
                          {property.floors
                            ? property.floors.map((floor, floorIndex) => (
                                <div
                                  key={floorIndex}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginBottom: "5px",
                                    width: "100%",
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                  }}
                                >
                                  {this.props.edit ? (
                                    <>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-around",
                                          width: "100%",
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: colors.white,
                                            fontSize: 8,
                                            fontFamily: "Montserrat",
                                            fontWeight: fontWeights.normal,
                                            textAlign: "left",
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          Etage
                                        </span>
                                        <span
                                          style={{
                                            color: colors.white,
                                            fontSize: 8,
                                            fontFamily: "Montserrat",
                                            fontWeight: fontWeights.normal,
                                            textAlign: "left",
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          Loyer
                                        </span>
                                        <span
                                          style={{
                                            color: colors.white,
                                            fontSize: 8,
                                            fontFamily: "Montserrat",
                                            fontWeight: fontWeights.normal,
                                            textAlign: "left",
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          Surface
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          width: "100%",
                                          justifyContent: "space-around",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          value={floor.name}
                                          onChange={(e) =>
                                            this.handleFloorChange(
                                              index,
                                              floorIndex,
                                              "name",
                                              e.target.value
                                            )
                                          }
                                          onBlur={(e) =>
                                            this.handleFloorChange(
                                              index,
                                              floorIndex,
                                              "name",
                                              e.target.value,
                                              true
                                            )
                                          }
                                          style={{
                                            color: colors.white,
                                            backgroundColor: colors.lightBlue,
                                            border: "none",
                                            borderBottom: "0.2px solid",
                                            borderColor:
                                              "rgba(255,255,255,0.5)",
                                            fontSize: fontSizes.small,
                                            fontFamily: "Montserrat",
                                            fontWeight: fontWeights.bold,
                                            marginBottom: "2px",
                                            width: "30%",
                                          }}
                                        />
                                        <input
                                          type="number"
                                          value={floor.price}
                                          onChange={(e) =>
                                            this.handleFloorChange(
                                              index,
                                              floorIndex,
                                              "price",
                                              e.target.value
                                            )
                                          }
                                          onBlur={(e) =>
                                            this.handleFloorChange(
                                              index,
                                              floorIndex,
                                              "price",
                                              e.target.value,
                                              true
                                            )
                                          }
                                          style={{
                                            color: colors.blue,
                                            backgroundColor: colors.lightBlue,
                                            border: "none",
                                            borderBottom: "0.2px solid",
                                            fontSize: fontSizes.small,
                                            fontFamily: "Montserrat",
                                            borderColor:
                                              "rgba(255,255,255,0.5)",
                                            fontWeight: fontWeights.bold,
                                            marginBottom: "2px",
                                            width: "30%",
                                          }}
                                          // placeholder="Price (€/m²/an)"
                                        />
                                        <input
                                          type="number"
                                          value={floor.surface}
                                          onChange={(e) =>
                                            this.handleFloorChange(
                                              index,
                                              floorIndex,
                                              "surface",
                                              e.target.value
                                            )
                                          }
                                          onBlur={(e) =>
                                            this.handleFloorChange(
                                              index,
                                              floorIndex,
                                              "surface",
                                              e.target.value,
                                              true
                                            )
                                          }
                                          style={{
                                            color: colors.blue,
                                            backgroundColor: colors.lightBlue,
                                            border: "none",
                                            borderBottom: "0.2px solid",
                                            borderColor:
                                              "rgba(255,255,255,0.5)",
                                            fontSize: fontSizes.small,
                                            fontFamily: "Montserrat",
                                            fontWeight: fontWeights.bold,
                                            width: "30%",
                                          }}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <span
                                      style={{
                                        color: colors.white,
                                        fontSize: fontSizes.small,
                                        fontFamily: "Montserrat",
                                        fontWeight: fontWeights.bold,
                                        textAlign: "left",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {floor.name}

                                      <span
                                        style={{
                                          color: colors.blue,
                                          fontSize: fontSizes.small,
                                          fontFamily: "Montserrat",
                                          fontWeight: fontWeights.bold,
                                          textAlign: "left",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {floor.price != null
                                          ? " - " + floor.price + "€/m²/an"
                                          : ""}
                                      </span>
                                      {" - "}
                                      <span
                                        style={{
                                          color: colors.blue,
                                          fontSize: fontSizes.small,
                                          fontFamily: "Montserrat",
                                          fontWeight: fontWeights.bold,
                                          textAlign: "left",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {floor.surface != null
                                          ? floor.surface.toLocaleString(
                                              "fr-FR"
                                            ) + "  m²"
                                          : null}
                                      </span>
                                    </span>
                                  )}
                                </div>
                              ))
                            : null}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            borderRadius: 10,
                            justifyContent: "center",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          {/* Charges Price */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              borderRadius: 10,
                              paddingLeft: 5,
                              paddingRight: 5,
                              justifyContent: "flex-start",
                              width: "100%",
                            }}
                          >
                            {this.props.edit ? (
                              <>
                                <span
                                  style={{
                                    color: colors.white,
                                    fontSize: 8,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.medium,
                                  }}
                                >
                                  Charges
                                </span>
                                <input
                                  className="mb-2"
                                  step="0.01"
                                  type="number"
                                  value={property.charges_price}
                                  onBlur={(e) => {
                                    if (e.target.value.trim() !== "") {
                                      this.handlePropertyChange(
                                        index,
                                        "charges_price",
                                        e.target.value,
                                        true
                                      );
                                    }
                                  }}
                                  onChange={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "charges_price",
                                      e.target.value
                                    )
                                  }
                                  style={{
                                    width: "100%",
                                    color: colors.white,
                                    backgroundColor: colors.lightBlue,
                                    border: "none",
                                    borderBottom: "0.2px solid",
                                    borderColor: "rgba(255,255,255, 0.5)",
                                    fontSize: fontSizes.small,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.bold,
                                  }}
                                />
                              </>
                            ) : (
                              <span
                                style={{
                                  color: colors.white,
                                  fontSize: fontSizes.small,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.normal,
                                }}
                              >
                                {property.charges_price
                                  ? property.charges_price + "€/m²/an"
                                  : "N/A"}
                              </span>
                            )}
                          </div>

                          {/* Office Taxes */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              borderRadius: 10,
                              paddingLeft: 5,
                              paddingRight: 5,
                              justifyContent: "flex-start",
                              width: "100%",
                            }}
                          >
                            {this.props.edit ? (
                              <>
                                <span
                                  style={{
                                    color: colors.white,
                                    fontSize: 8,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.medium,
                                  }}
                                >
                                  Taxes bureaux
                                </span>
                                <input
                                  className="mb-2"
                                  step="0.01"
                                  type="number"
                                  value={property.office_taxes}
                                  onChange={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "office_taxes",
                                      e.target.value
                                    )
                                  }
                                  onBlur={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "office_taxes",
                                      e.target.value,
                                      true
                                    )
                                  }
                                  style={{
                                    color: colors.white,
                                    backgroundColor: colors.lightBlue,
                                    border: "none",
                                    width: "100%",
                                    borderBottom: "0.2px solid",
                                    borderColor: "rgba(255,255,255, 0.5)",
                                    fontSize: fontSizes.small,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.bold,
                                  }}
                                />
                              </>
                            ) : (
                              <span
                                style={{
                                  color: colors.white,
                                  fontSize: fontSizes.small,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.medium,
                                }}
                              >
                                {property.office_taxes
                                  ? property.office_taxes + "€"
                                  : "N/A"}
                              </span>
                            )}
                          </div>

                          {/* Taxation */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              borderRadius: 10,
                              paddingLeft: 5,
                              paddingRight: 5,
                              justifyContent: "flex-start",
                              width: "100%",
                            }}
                          >
                            {this.props.edit ? (
                              <>
                                <span
                                  style={{
                                    color: colors.white,
                                    fontSize: 8,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.medium,
                                  }}
                                >
                                  Fiscalité (TF/TEOM)
                                </span>
                                <input
                                  className="mb-2"
                                  step="0.01"
                                  type="number"
                                  value={property.taxation}
                                  onChange={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "taxation",
                                      e.target.value
                                    )
                                  }
                                  onBlur={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "taxation",
                                      e.target.value,
                                      true
                                    )
                                  }
                                  style={{
                                    color: colors.white,
                                    backgroundColor: colors.lightBlue,
                                    border: "none",
                                    width: "100%",
                                    borderBottom: "0.2px solid",
                                    borderColor: "rgba(255,255,255, 0.5)",
                                    fontSize: fontSizes.small,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.bold,
                                  }}
                                />
                              </>
                            ) : (
                              <span
                                style={{
                                  color: colors.white,
                                  fontSize: fontSizes.small,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.medium,
                                }}
                              >
                                {property.taxation
                                  ? property.taxation + "€"
                                  : "N/A"}
                              </span>
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            borderRadius: 10,
                            justifyContent: "center",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          {/* Parking Spaces */}

                          {this.props.edit ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                borderRadius: 10,
                                justifyContent: "flex-start",
                                width: "100%",
                                paddingLeft: 5,
                                paddingRight: 5,
                              }}
                            >
                              <span
                                style={{
                                  color: colors.white,
                                  fontSize: 8,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.medium,
                                }}
                              >
                                Places
                              </span>
                              <input
                                className="mb-2"
                                type="number"
                                value={property.parking_spaces}
                                onChange={(e) =>
                                  this.handlePropertyChange(
                                    index,
                                    "parking_spaces",
                                    e.target.value
                                  )
                                }
                                onBlur={(e) =>
                                  this.handlePropertyChange(
                                    index,
                                    "parking_spaces",
                                    e.target.value,
                                    true
                                  )
                                }
                                style={{
                                  width: "100%",
                                  color: colors.white,
                                  backgroundColor: colors.lightBlue,
                                  border: "none",
                                  borderBottom: "0.2px solid",
                                  borderColor: "rgba(255,255,255, 0.5)",
                                  fontSize: fontSizes.small,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.bold,
                                }}
                              />
                            </div>
                          ) : (
                            <span
                              style={{
                                color: colors.white,
                                fontSize: fontSizes.small,
                                fontFamily: "Montserrat",
                                fontWeight: fontWeights.bold,
                              }}
                            >
                              {property.parking_spaces != 0
                                ? property.parking_spaces + " U"
                                : "N/A"}
                            </span>
                          )}

                          {/* Parking Price */}

                          {this.props.edit ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                borderRadius: 10,
                                paddingLeft: 5,
                                paddingRight: 5,
                                justifyContent: "flex-start",
                                width: "100%",
                              }}
                            >
                              <span
                                style={{
                                  color: colors.white,
                                  fontSize: 8,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.medium,
                                }}
                              >
                                Loyer parking
                              </span>
                              <input
                                className="mb-2"
                                type="number"
                                step="0.01"
                                value={property.parking_price}
                                onChange={(e) =>
                                  this.handlePropertyChange(
                                    index,
                                    "parking_price",
                                    e.target.value
                                  )
                                }
                                onBlur={(e) => {
                                  if (e.target.value.trim() !== "") {
                                    this.handlePropertyChange(
                                      index,
                                      "parking_price",
                                      e.target.value,
                                      true
                                    );
                                  }
                                }}
                                style={{
                                  width: "100%",
                                  color: colors.white,
                                  backgroundColor: colors.lightBlue,
                                  border: "none",
                                  borderBottom: "0.2px solid",
                                  borderColor: "rgba(255,255,255, 0.5)",
                                  fontSize: fontSizes.small,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.bold,
                                }}
                              />
                            </div>
                          ) : (
                            <span
                              style={{
                                color: colors.white,
                                fontSize: fontSizes.small,
                                fontFamily: "Montserrat",
                                fontWeight: fontWeights.medium,
                              }}
                            >
                              {property.parking_price
                                ? property.parking_price + "€/U/an"
                                : "N/A"}
                            </span>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",

                            flex: 2,
                            justifyContent: "center",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            overflowX: "hidden",
                            overflowY: "scroll",
                          }}
                        >
                          {property.public_transports
                            .sort(
                              (a, b) =>
                                a.distance_to_property_minutes -
                                b.distance_to_property_minutes
                            )
                            .slice(0, 3)
                            .map((transport) => (
                              <span
                                style={{
                                  color: colors.white,
                                  fontSize: fontSizes.small,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.bold,
                                  textAlign: "left",
                                  // textOverflow: 'ellipsis',
                                  // overflow: 'hidden',
                                  // whiteSpace: 'nowrap',
                                }}
                              >
                                {transport.name} (
                                <span
                                  style={{
                                    color: colors.blue,
                                    fontSize: fontSizes.small,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.bold,
                                    textAlign: "left",
                                    // textOverflow: 'ellipsis',
                                    // overflow: 'hidden',
                                    // whiteSpace: 'nowrap',
                                  }}
                                >
                                  {transport.distance_to_property_minutes} min
                                </span>
                                ) <br />
                                <div style={{ display: "flex" }}>
                                  {transport.lines.map((line) => (
                                    <div
                                      className="me-1"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 3,
                                        width: 20,
                                        color: "#" + line.text_color,
                                        backgroundColor: "#" + line.color,
                                      }}
                                    >
                                      {line.name}
                                    </div>
                                  ))}
                                </div>
                              </span>
                            ))}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            borderRadius: 10,
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          {/* Brokers */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              borderRadius: 10,
                              justifyContent: "flex-start",
                              width: "100%",
                              paddingBottom: "5px",
                            }}
                          >
                            {this.props.edit ? (
                              <>
                                <span
                                  style={{
                                    color: colors.white,
                                    fontSize: 8,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.medium,
                                    paddingBottom: "5px",
                                  }}
                                >
                                  Brokers
                                </span>
                                <input
                                  type="text"
                                  value={property.brokers}
                                  onChange={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "brokers",
                                      e.target.value
                                    )
                                  }
                                  onBlur={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "brokers",
                                      e.target.value,
                                      true
                                    )
                                  }
                                  style={{
                                    width: "100%",
                                    color: colors.white,
                                    backgroundColor: colors.lightBlue,
                                    border: "none",
                                    borderBottom: "0.2px solid",
                                    borderColor: "rgba(255,255,255, 0.5)",
                                    fontSize: fontSizes.small,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.bold,
                                    paddingBottom: "5px",
                                  }}
                                />
                              </>
                            ) : (
                              <span
                                style={{
                                  color: colors.white,
                                  fontSize: fontSizes.small,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.medium,
                                  textAlign: "left",
                                }}
                              >
                                {property.brokers}
                              </span>
                            )}
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flex: 3,
                            borderRadius: 10,
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexDirection: "column",
                            overflowX: "hidden",
                            overflowY: "scroll",
                          }}
                        >
                          {/* Services */}
                          {this.props.edit ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                borderRadius: 10,
                                paddingLeft: 5,
                                paddingRight: 5,
                                justifyContent: "flex-start",
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              <span
                                style={{
                                  color: colors.white,
                                  fontSize: 8,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.medium,
                                  paddingBottom: "5px",
                                }}
                              >
                                Services
                              </span>
                              <textarea
                                value={property.services.join("\n")}
                                onChange={(e) =>
                                  this.handlePropertyChange(
                                    index,
                                    "services",
                                    e.target.value.split("\n")
                                  )
                                }
                                onBlur={(e) =>
                                  this.handlePropertyChange(
                                    index,
                                    "services",
                                    e.target.value.split("\n"),
                                    true
                                  )
                                }
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  color: colors.white,
                                  backgroundColor: colors.lightBlue,
                                  border: "none",
                                  borderBottom: "0.2px solid",
                                  borderColor: "rgba(255,255,255, 0.5)",
                                  fontSize: fontSizes.small,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.bold,
                                  paddingBottom: "5px",
                                }}
                              />
                            </div>
                          ) : (
                            <span
                              style={{
                                color: colors.white,
                                fontSize: fontSizes.small,
                                fontFamily: "Montserrat",
                                fontWeight: fontWeights.medium,
                                textAlign: "left",
                                width: "100%",
                              }}
                            >
                              {property.services.map((service, index) => (
                                <React.Fragment key={index}>
                                  {service}
                                  <br />
                                </React.Fragment>
                              ))}
                            </span>
                          )}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flex: 3,
                            borderRadius: 10,
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexDirection: "column",
                            height: "100%",
                            overflowX: "hidden",
                            overflowY: "scroll",
                          }}
                        >
                          {/* Comments */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              borderRadius: 10,
                              paddingLeft: 5,
                              paddingRight: 5,
                              justifyContent: "flex-start",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            {this.props.edit ? (
                              <>
                                <span
                                  style={{
                                    color: colors.white,
                                    fontSize: 8,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.medium,
                                    paddingBottom: "5px",
                                  }}
                                >
                                  Commentaires
                                </span>
                                <textarea
                                  value={property.comments}
                                  onChange={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "comments",
                                      e.target.value
                                    )
                                  }
                                  onBlur={(e) =>
                                    this.handlePropertyChange(
                                      index,
                                      "comments",
                                      e.target.value,
                                      true
                                    )
                                  }
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    color: colors.white,
                                    backgroundColor: colors.lightBlue,
                                    border: "none",
                                    borderBottom: "0.2px solid",
                                    borderColor: "rgba(255,255,255, 0.5)",
                                    fontSize: fontSizes.small,
                                    fontFamily: "Montserrat",
                                    fontWeight: fontWeights.bold,
                                    paddingBottom: "5px",
                                  }}
                                />
                              </>
                            ) : (
                              <span
                                style={{
                                  color: colors.white,
                                  fontSize: fontSizes.small,
                                  fontFamily: "Montserrat",
                                  fontWeight: fontWeights.medium,
                                  textAlign: "left",
                                }}
                              >
                                {property.comments}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "5%",
                          height: "100%",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spring
                          backgroundColor={
                            property.selected
                              ? colors.lightBlue
                              : colors.lightBlue
                          }
                        >
                          {(props) => (
                            <div
                              className="m-1"
                              onClick={() =>
                                this.props.displayPropertyModal(property)
                              }
                              style={{
                                cursor: "pointer",
                                width: "50%",
                                aspectRatio: "1/1",
                                borderRadius: "50%",
                                maxWidth: 40,

                                backgroundColor: colors.background,
                                display: "flex",
                                justifyContent: "center",
                                position: "relative",
                                alignItems: "center",
                                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.10)",
                              }}
                            >
                              <animated.div
                                style={{
                                  display: "flex",
                                  width: "70%",
                                  height: "70%",
                                  borderRadius: "50%",

                                  justifyContent: "center",
                                  alignItems: "center",
                                  ...props,
                                }}
                              ></animated.div>
                              <EyeFill
                                style={{ position: "absolute" }}
                                size={"50%"}
                                color={colors.background}
                              ></EyeFill>
                            </div>
                          )}
                        </Spring>
                        {!this.props.userMode && (
                          <Spring
                            backgroundColor={
                              property.selected ? colors.brown : colors.white
                            }
                          >
                            {(props) => (
                              <div
                                className="m-1"
                                onClick={() =>
                                  this.props.selectProperty(
                                    property.id,
                                    !property.selected
                                  )
                                }
                                style={{
                                  cursor: "pointer",
                                  width: "50%",
                                  maxWidth: 40,

                                  aspectRatio: "1/1",
                                  borderRadius: "50%",
                                  backgroundColor: colors.background,
                                  display: "flex",
                                  justifyContent: "center",
                                  position: "relative",
                                  alignItems: "center",
                                  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.10)",
                                }}
                              >
                                <animated.div
                                  style={{
                                    display: "flex",
                                    width: "70%",
                                    height: "70%",
                                    borderRadius: "50%",

                                    justifyContent: "center",
                                    alignItems: "center",
                                    ...props,
                                  }}
                                ></animated.div>
                                <CheckLg
                                  style={{ position: "absolute" }}
                                  size={"50%"}
                                  color={colors.background}
                                ></CheckLg>
                              </div>
                            )}
                          </Spring>
                        )}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          )}
        </AutoSizer>
      </div>
    );
  }
}

export default withRouter(TableView);
