import React, { Component } from "react";
import { colors } from "../colors";
import { fontSizes } from "../fontSizes";
import { fontWeights } from "../fontWeights";
import {
  exportProjectExcel,
  exportProjectPPTX,
  fetchProjectToken,
  generateNewProjectToken,
  getPropertiesByProject,
} from "../api";
import Button from "./Button";
import { Clipboard2Fill } from "react-bootstrap-icons";
import { withRouter } from "../withRouter";

class Exports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      properties: [],
    };
  }

  async componentDidMount() {
    try {
      const properties = await getPropertiesByProject(this.props.project.id);
      const domainName = window.location.hostname;
      const protocol = window.location.protocol;
      const tokenUser = await fetchProjectToken(this.props.project.id);

      this.setState({
        properties,
        tokenUser: protocol + "//" + domainName + "/view/" + tokenUser,
        loading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  exportPptx = async () => {
    try {
      const response = await exportProjectPPTX(this.props.project.id);
    } catch (error) {
      console.error(
        `Error exporting project with ID ${this.props.project.id}:`,
        error
      );
      throw error;
    }
  };

  exportExcel = async () => {
    try {
      const response = await exportProjectExcel(this.props.project.id);
    } catch (error) {
      console.error(
        `Error exporting project with ID ${this.props.project.id}:`,
        error
      );
      throw error;
    }
  };

  generateNewToken = async () => {
    try {
      const newToken = await generateNewProjectToken(this.props.project.id);
      const domainName = window.location.hostname;
      const protocol = window.location.protocol;
      this.setState({
        tokenUser: protocol + "//" + domainName + "/view/" + newToken,
      });
    } catch (error) {
      console.error(
        `Error generating new token for project with ID ${this.props.project.id}:`,
        error
      );
      throw error;
    }
  };

  render() {
    return (
      <div
        className="p-2"
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <div
          className="mb-3"
          style={{
            width: "100%",
            flexDirection: "column",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <span
            className="mb-2"
            style={{
              color: colors.blue,
              fontSize: fontSizes.large,
              fontFamily: "Montserrat",
              fontWeight: fontWeights.bold,
            }}
          >
            Exports
          </span>
          <div
            className="p-2 mb-2"
            style={{
              borderRadius: 10,
              border: `1px solid ${colors.blue}`,
            }}
          >
            <span
              style={{
                color: colors.blue,
                fontSize: fontSizes.large,
                fontFamily: "Montserrat",
                fontWeight: fontWeights.bold,
              }}
            >
              <span
                style={{
                  color: colors.brown,
                  fontSize: fontSizes.large,
                  fontFamily: "Montserrat",
                  fontWeight: fontWeights.bold,
                }}
              >
                {
                  this.state.properties.filter((property) => property.selected)
                    .length
                }
              </span>{" "}
              bien sélectionnés
            </span>
          </div>
          <span
            className="mb-1"
            style={{
              color: colors.lightBlue,
              fontSize: fontSizes.medium,
              fontFamily: "Montserrat",
              fontWeight: fontWeights.medium,
            }}
          >
            Lien client
          </span>
          <div
            className="mb-2"
            style={{
              borderRadius: 10,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 10px",
              border: `1px solid ${colors.lightBlue}`,
              backgroundColor: colors.white,
              height: 46,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                maxWidth: "calc(100% - 30px)",
                overflow: "hidden",
              }}
            >
              <span
                style={{
                  color: colors.lightBlue,
                  fontSize: fontSizes.medium,
                  fontFamily: "Montserrat",
                  fontWeight: fontWeights.medium,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {this.state.tokenUser?.slice(0, 30) + "..."}
              </span>
            </div>
            <Clipboard2Fill
              onClick={() => {
                navigator.clipboard.writeText(this.state.tokenUser);
              }}
              className="ms-2"
              style={{ cursor: "pointer" }}
              color={colors.blue}
            ></Clipboard2Fill>
          </div>

          <Button
            onClick={this.generateNewToken.bind(this)}
            title={"Nouveau lien"}
          ></Button>

          <span
            className="mb-1  mt-3"
            style={{
              color: colors.lightBlue,
              fontSize: fontSizes.medium,
              fontFamily: "Montserrat",
              fontWeight: fontWeights.medium,
            }}
          >
            Powerpoint
          </span>
          <Button
            enabled={
              this.state.properties.filter((property) => property.selected)
                .length > 0
            }
            onClick={this.exportPptx.bind(this)}
            title={"Exporter"}
          ></Button>
          <span
            className="mb-1 mt-3"
            style={{
              color: colors.lightBlue,
              fontSize: fontSizes.medium,
              fontFamily: "Montserrat",
              fontWeight: fontWeights.medium,
            }}
          >
            Excel
          </span>
          <Button
            enabled={
              this.state.properties.filter((property) => property.selected)
                .length > 0
            }
            onClick={this.exportExcel.bind(this)}
            title={"Exporter"}
          ></Button>
        </div>
      </div>
    );
  }
}

export default withRouter(Exports);
