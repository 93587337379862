import React, { Component } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { colors } from "../colors";
import { fontWeights } from "../fontWeights";
import { fontSizes } from "../fontSizes";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { getBoards } from "../api";
import { Spring, animated } from "@react-spring/web";

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBarVisible: false,
      nameSelected: "Gestion du projet",
      childSelected: null,
      list: [
        {
          name: "Gestion du projet",
          childs: [],
        },
        {
          name: "Documents",
          childs: [],
        },
        {
          name: "Immobilier",
          childs: [],
        },
        {
          name: "Export",
          childs: [],
        },
      ],
    };
  }

  render() {
    return (
      <Spring
        minWidth={this.state.sideBarVisible ? 300 : 0}
        width={this.state.sideBarVisible ? "20%" : "0%"}
        opacity={this.state.sideBarVisible ? 1 : 0}
      >
        {(props) => (
          <>
            <div
              onMouseEnter={() => this.setState({ sideBarVisible: true })}
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                height: "100%",
                width: 20,
              }}
            ></div>
            <animated.div
              onMouseLeave={() => this.setState({ sideBarVisible: false })}
              style={{
                ...props,
                opacity: 1,
                height: "100%",
                backgroundColor: colors.white,
                position: "relative",
                zIndex: 100,
              }}
            >
              <Spring right={this.state.sideBarVisible ? 10 : -50}>
                {(props) => (
                  <animated.div
                    onClick={() =>
                      this.setState({
                        sideBarVisible: !this.state.sideBarVisible,
                      })
                    }
                    style={{
                      ...props,
                      transform: !this.state.sideBarVisible
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                      position: "absolute",
                      top: 5,
                      zIndex: 100,
                      cursor: "pointer",
                    }}
                  >
                    <IoIosArrowDropleftCircle
                      color={colors.brown}
                      size={26}
                    ></IoIosArrowDropleftCircle>
                  </animated.div>
                )}
              </Spring>
              {this.state.sideBarVisible && (
                <animated.div
                  style={{
                    ...props,
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <Row className="pt-3 ps-5 pe-2">
                    <Col md={12}>
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          height: 55,
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: 55,
                            height: 55,
                            borderRadius: 55 / 2,
                            backgroundColor: colors.brown,
                            position: "absolute",
                            zIndex: 0,
                            top: 0,
                            left: -55 / 3,
                          }}
                        ></div>
                        <span
                          style={{
                            zIndex: 1,
                            color: colors.blue,
                            fontWeight: fontWeights.black,
                            fontSize: fontSizes.large,
                            textAlign: "left",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {this.props.project?.name}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  {this.state.list.map((item, index) => (
                    <Row className="ps-5 pt-3" key={index}>
                      <Col
                        md={12}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            position: "relative",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <span
                            onClick={() =>
                              item.name != "Planches"
                                ? (this.setState({
                                    nameSelected: item.name,
                                    childSelected: null,
                                  }),
                                  this.props.changeScreen(item.name))
                                : null
                            }
                            style={{
                              cursor:
                                item.name != "Planches" ? "pointer" : undefined,
                              color: colors.blue,
                              fontWeight:
                                this.state.nameSelected == item.name
                                  ? fontWeights.black
                                  : fontWeights.bold,
                              fontSize: fontSizes.medium,
                              textAlign: "left",
                              fontFamily: "Montserrat",
                            }}
                          >
                            {item.name}
                          </span>
                          {item.name === this.state.nameSelected && (
                            <div
                              style={{
                                position: "absolute",
                                left: -15,
                                width: 10,
                                height: 10,

                                borderRadius: 20 / 2,
                                backgroundColor: colors.brown,
                              }}
                            ></div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  ))}
                </animated.div>
              )}
            </animated.div>
          </>
        )}
      </Spring>
    );
  }
}

export default SideBar;
