import React, { Component } from "react";
import { colors } from "../colors";
import { fontSizes } from "../fontSizes";
import { fontWeights } from "../fontWeights";
import {
  deleteDocument,
  getDocumentFile,
  getDocumentsByProject,
  uploadDocument,
} from "../api";
import Button from "./Button";
import { withRouter } from "../withRouter";
import { Row, Col, Spinner, Form } from "react-bootstrap";

import Dropzone from "react-dropzone";
import {
  FileEarmarkArrowUpFill,
  FileEarmarkFill,
  HourglassTop,
  CheckCircleFill,
  FileEarmarkXFill,
  EyeFill,
  ExclamationTriangleFill,
  XCircleFill,
} from "react-bootstrap-icons";

class FileDropzone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filesToUpload: [],
    };

    this.interval = null;
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          height: "30%",

          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Dropzone
          onDrop={(acceptedFiles) => {
            const newFiles = acceptedFiles.map((file) => ({
              file: file,
              layout_type: 2,
            }));
            this.setState((prevState) => ({
              filesToUpload: [...prevState.filesToUpload, ...newFiles],
            }));
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              className="mb-2"
              {...getRootProps()}
              style={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: colors.blue,
                height: "100%",
                width: "100%",
                borderWidth: 1,
                borderColor: "black",
                borderStyle: "solid",
              }}
            >
              {this.props.loading ? (
                <Spinner variant="light"></Spinner>
              ) : (
                <>
                  <input {...getInputProps()} />
                  {this.state.filesToUpload.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        wrapDirection: "row",
                        overflow: "scroll",
                      }}
                    >
                      {this.state.filesToUpload.map((file, index) => (
                        <div
                          key={index}
                          style={{
                            margin: "10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          <div
                            onClick={(event) => {
                              event.stopPropagation();
                              this.setState({
                                filesToUpload: this.state.filesToUpload.filter(
                                  (file, i) => i != index
                                ),
                              });
                            }}
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              right: 0,
                              top: -10,
                            }}
                          >
                            <XCircleFill color={colors.brown}></XCircleFill>
                          </div>
                          <FileEarmarkFill
                            color={colors.white}
                            size={30}
                          ></FileEarmarkFill>
                          <span
                            className="mt-1"
                            style={{
                              color: colors.white,
                              fontSize: fontSizes.normal,
                              fontFamily: "Montserrat",
                              fontWeight: fontWeights.medium,
                            }}
                          >
                            {file.file.name.length > 20
                              ? file.file.name.substring(0, 20) + "..."
                              : file.file.name}
                          </span>
                          <Form.Select
                            className="mt-2"
                            aria-label="Document Layout Select"
                            value={file.layout_type}
                            onClick={(event) => event.stopPropagation()}
                            onChange={(event) => {
                              this.setState({
                                filesToUpload: this.state.filesToUpload.map(
                                  (file, i) => {
                                    if (i == index) {
                                      return {
                                        file: file.file,
                                        layout_type: event.target.value,
                                      };
                                    } else {
                                      return file;
                                    }
                                  }
                                ),
                              });
                            }}
                          >
                            <option value={1}>Un seul bien</option>
                            <option value={2}>Multiples biens</option>
                            <option value={3}>Multiples biens par page</option>
                          </Form.Select>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FileEarmarkArrowUpFill
                        className="mb-2"
                        size={50}
                        color={colors.white}
                      ></FileEarmarkArrowUpFill>
                      <span
                        style={{
                          color: colors.white,
                          fontSize: fontSizes.small,
                          fontFamily: "Montserrat",
                          fontWeight: fontWeights.medium,
                        }}
                      >
                        Faites glisser des documents ou cliquez.
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </Dropzone>

        <Button
          onClick={async () => {
            await this.props.handleUpload(this.state.filesToUpload);
            this.setState({ filesToUpload: [] });
          }}
          enabled={this.state.filesToUpload.length != 0}
          title={"Uploader et traiter"}
        ></Button>
      </div>
    );
  }
}
class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      loadingUpload: false,

      filesToUpload: [],
    };
  }

  handleUpload = async (filesToUpload) => {
    this.setState({ loadingUpload: true });
    for (const file of filesToUpload) {
      try {
        const newDocument = await uploadDocument(
          this.props.project.id,
          file.file,
          file.layout_type
        );
        const documents = [...this.state.documents, newDocument];

        this.setState({
          documents: documents,
        });
        this.launchInterval(documents);
      } catch (error) {
        console.error("Error uploading document:", error);
      }
    }
    this.setState({ filesToUpload: [], loadingUpload: false });
  };

  launchInterval(documents) {
    const isPending = documents.some(
      (document) =>
        document.status == "pending" || document.status == "processing"
    );

    if (!isPending && this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    } else if (isPending && !this.interval) {
      this.interval = setInterval(async () => {
        const documents = await this.getDocuments(false);
        const stillPending = documents.some(
          (document) =>
            document.status === "pending" || document.status === "processing"
        );
        if (!stillPending) {
          clearInterval(this.interval);
          this.interval = null;
        }
      }, 5000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getDocumentFile = async (documentInfo) => {
    try {
      const response = await getDocumentFile(documentInfo.id);
    } catch (error) {
      console.error(
        `Error getting file for document with ID ${documentInfo.id}:`,
        error
      );
    }
  };

  deleteDocument = async (documentId) => {
    try {
      const response = await deleteDocument(documentId);
      this.setState({
        documents: this.state.documents.filter(
          (document) => document.id != documentId
        ),
      });
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  async componentDidMount() {
    const documents = await this.getDocuments();

    this.launchInterval(documents);
  }

  async getDocuments(loading = true) {
    try {
      if (loading) this.setState({ loading: true });
      let documents = await getDocumentsByProject(this.props.project.id);
      documents = documents.sort((a, b) => a.id - b.id);
      this.setState({ documents, loading: false });
      return documents;
    } catch (error) {
      console.error("Error getting documents:", error);
    }
  }

  render() {
    return (
      <div
        className="p-2"
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <div
          className="mb-3"
          style={{
            flex: 3,
            height: "70%",
            width: "100%",
            flexDirection: "column",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <span
            style={{
              color: colors.blue,
              fontSize: fontSizes.large,
              fontFamily: "Montserrat",
              fontWeight: fontWeights.bold,
            }}
          >
            Documents
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              flex: 1,
              width: "100%",
              overflowY: "scroll",
            }}
          >
            <div
              className="mb-2"
              style={{
                display: "flex",
                height: 46,
                borderRadius: 10,
                width: "100%",
                backgroundColor: colors.blue,
              }}
            >
              <div
                style={{
                  display: "flex",
                  height: 46,
                  flex: 2,
                  borderRadius: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: colors.white,
                    fontSize: fontSizes.small,
                    fontFamily: "Montserrat",
                    fontWeight: fontWeights.medium,
                  }}
                >
                  Nom
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  height: 46,
                  flex: 1,
                  borderRadius: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: colors.white,
                    fontSize: fontSizes.small,
                    fontFamily: "Montserrat",
                    fontWeight: fontWeights.medium,
                  }}
                >
                  Ajouté le
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  height: 46,
                  flex: 1,
                  borderRadius: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: colors.white,
                    fontSize: fontSizes.small,
                    fontFamily: "Montserrat",
                    fontWeight: fontWeights.medium,
                  }}
                >
                  Biens extraits
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  height: 46,
                  flex: 1,
                  borderRadius: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: colors.white,
                    fontSize: fontSizes.small,
                    fontFamily: "Montserrat",
                    fontWeight: fontWeights.medium,
                  }}
                >
                  Status
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  height: 46,
                  flex: 1,
                  borderRadius: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: colors.white,
                    fontSize: fontSizes.small,
                    fontFamily: "Montserrat",
                    fontWeight: fontWeights.medium,
                  }}
                >
                  Action
                </span>
              </div>
            </div>
            {this.state.loading ? (
              <div
                style={{
                  display: "flex",

                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner></Spinner>
              </div>
            ) : (
              this.state.documents.map((document, index) => (
                <div
                  key={document.id}
                  className="mb-2 px-2"
                  style={{
                    display: "flex",
                    height: 32,
                    borderRadius: 10,
                    width: "100%",
                    backgroundColor: colors.lightBlue,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      height: 32,
                      flex: 2,
                      borderRadius: 10,
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                    }}
                  >
                    <span
                      style={{
                        color: colors.white,
                        fontSize: fontSizes.small,
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.medium,
                        textOverflow: "ellipsis", // add this line
                        overflow: "hidden", // add this line
                        whiteSpace: "nowrap", // add this line
                      }}
                    >
                      {document.name}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: 32,
                      flex: 1,
                      borderRadius: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        color: colors.white,
                        fontSize: fontSizes.small,
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.medium,
                      }}
                    >
                      {new Date(document.uploaded_at).toLocaleDateString(
                        "fr-FR",
                        { day: "2-digit", month: "2-digit", year: "2-digit" }
                      )}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: 32,
                      flex: 1,
                      borderRadius: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        color: colors.white,
                        fontSize: fontSizes.small,
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.medium,
                      }}
                    >
                      {document.properties?.length}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: 32,
                      flex: 1,
                      borderRadius: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        color: colors.white,
                        fontSize: fontSizes.small,
                        fontFamily: "Montserrat",
                        fontWeight: fontWeights.medium,
                      }}
                    >
                      {document.status == "pending" ? (
                        <HourglassTop></HourglassTop>
                      ) : document.status == "processing" ? (
                        <Spinner size="sm"></Spinner>
                      ) : document.status == "failed" ? (
                        <ExclamationTriangleFill></ExclamationTriangleFill>
                      ) : (
                        <CheckCircleFill></CheckCircleFill>
                      )}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: 32,
                      flex: 1,
                      borderRadius: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="p-1"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        height: "100%",
                      }}
                    >
                      <div
                        onClick={() => this.deleteDocument(document.id)}
                        className="me-1"
                        style={{
                          cursor: "pointer",

                          width: 40,
                          display: "flex",
                          height: "100%",
                          backgroundColor: colors.blue,
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 10,
                        }}
                      >
                        <FileEarmarkXFill
                          color={colors.white}
                          size={12}
                        ></FileEarmarkXFill>
                      </div>
                      <div
                        onClick={() => this.getDocumentFile(document)}
                        style={{
                          cursor: "pointer",
                          width: 40,
                          display: "flex",

                          height: "100%",
                          backgroundColor: colors.blue,
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 10,
                        }}
                      >
                        <EyeFill color={colors.white} size={12}></EyeFill>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <FileDropzone
          loading={this.state.loadingUpload}
          handleUpload={this.handleUpload.bind(this)}
          projectId={this.props.project.id}
        ></FileDropzone>
      </div>
    );
  }
}

export default withRouter(Documents);
