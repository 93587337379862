import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Projects from "./components/Projects";
import Project from "./components/ProjectMainView";
import { withRouter } from "./withRouter";
import { authToProject, refreshToken } from "./api";
import Login from "./components/Login";

class AuthenticatedRoutesAdmin extends Component {
  async componentDidMount() {
    if (!this.props.location.state?.userMode) {
      try {
        const tokens = await refreshToken(localStorage.getItem("token"));
        localStorage.setItem("token", tokens.access_token);
        this.props.navigate("/projects", {
          state: { userMode: false },
        });
      } catch (error) {
        console.error("Error:", error);
        this.props.navigate("/login");
      }
    }
  }

  render() {
    return (
      <div className="App">
        <Routes>
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:project_id" element={<Project />} />-
        </Routes>
      </div>
    );
  }
}

const AuthenticatedRoutesAdminWithRouter = withRouter(AuthenticatedRoutesAdmin);

class AuthenticatedRoutesUser extends Component {
  async componentDidMount() {
    try {
      localStorage.setItem("token", this.props.params.token);
      const project = await authToProject(this.props.params.token);
      this.props.navigate(`/projects/${project.project_id}`, {
        state: { userMode: true },
      });
    } catch (error) {
      localStorage.setItem("token", "");
      console.error("Error:", error);
      this.props.navigate("/login");
    }
  }
  render() {
    return (
      <div className="App">
        <Routes>
          <Route path="/projects/:project_id" element={<Project />} />-
        </Routes>
      </div>
    );
  }
}

const AuthenticatedRoutesWithRouter = withRouter(AuthenticatedRoutesUser);

class App extends Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/view/:token"
            element={<AuthenticatedRoutesWithRouter />}
          />
          <Route path="*" element={<AuthenticatedRoutesAdminWithRouter />} />
        </Routes>
      </Router>
    );
  }
}

export default App;
