import React, { Component } from 'react';
import { colors } from '../colors';
import { fontWeights } from '../fontWeights';
import { fontSizes } from '../fontSizes';
import { Spinner } from 'react-bootstrap';
import { Spring, animated } from '@react-spring/web';

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hover: false,
    };
  }

  handleClick = async () => {
    this.setState({ loading: true });
    await this.props.onClick();
    this.setState({ loading: false });
  };

  render() {
    const { loading, hover } = this.state;
    return (
      <Spring
        to={{
          borderBottomWidth: hover ? 3 : 1,
        }}
      >
        {(props) => (
          <animated.div
            onClick={
              this.props.enabled == null
                ? this.handleClick
                : this.props.enabled
                ? this.handleClick
                : null
            }
            onMouseLeave={() => this.setState({ hover: false })}
            onMouseEnter={() => this.setState({ hover: true })}
            style={{
              ...props,
              width: this.props.width ? this.props.width : 240,
              height: this.props.height ? this.props.height : 46,
              backgroundColor: this.props.bgColor
                ? this.props.bgColor
                : colors.blue,
              borderRadius: 10,
              borderWidth: 1,
              borderColor: colors.lightBlue,
              borderStyle: 'solid',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              opacity:
                this.props.enabled == null ? 1 : this.props.enabled ? 1 : 0.5,
            }}
          >
            {loading ? (
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                variant='light'
                aria-hidden='true'
              />
            ) : (
              <span
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: colors.white,
                  fontFamily: 'Montserrat',
                  fontWeight: fontWeights.bold,
                  fontSize: fontSizes.medium,
                }}
              >
                {this.props.title}
              </span>
            )}
          </animated.div>
        )}
      </Spring>
    );
  }
}

export default Button;
